import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../data.service';
import {SharedService} from '../shared.service';
import {RefreshTokenService} from '../refresh-token.service';
import { debug } from 'util';

declare var toastr: any;
declare var Nanobar: any;
declare var $: any;

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})



export class MessageComponent implements OnInit {

  nao = new Nanobar();
    
    userId: any;
    
    tokenData: any;
    token: any;
    clearTimeout: any;
    
    searchString = '';
    page = 1;
    offset = 10;

    eventType = '2';

    message : any;
    editDisable = false;

    eventsList = [];

    noEvents = false;

    datepickerModel: Date;
    minDate: Date;

    errorMessage= '';


    selectedTime: Date;
    timeModel:Date;
    selectedTimeUTC: any;


    userListPageCount = 0;
    userListPageLast = false;

    usersList = []
    usersTotalCount = 0

    itemToProcessIndex = 0;
    itemToProcess = 0;
    

    age = {
      'value': null,
      'filter': '0',
      'filterValue': 'All',
      'disable': true
    };

    joiningDate = {
      'value': null,
      'filter': '0',
      'filterValue': 'All',
      'disable': true
    };

    gender = {
      'value': null,
      'filter': '0',
      'filterValue': 'Any',
      'disable': true
    };

    profileVerification = {
      'value': null,
      'filter': '0',
      'filterValue': 'Any',
      'disable': true
    };

    constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
        this.minDate = new Date();
        this.datepickerModel = new Date();
        this.timeModel = new Date();
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('message');
        this.message = '';
        this.getEvents();
        
    }

    updateEventType(type){
      this.eventType = type;
    }

    async getEvents() {
      
      this.nao.go(30);
      window.localStorage.setItem('loader', 'true');

      this.tokenData = await this.refreshTokenService.checkRefreshToken();
      this.token = this.tokenData.token;

      this.dataService.getScheduledEvents(this.token).subscribe(
          (data) => {

              
              this.nao.go(100);
              window.localStorage.setItem('loader', 'false');
              var respData :any;
              respData = data;
              
              if (respData.is_success === true) {
                respData = respData.data;
                this.eventsList = [];
                  
                  for (let i = 0; i < respData.length; i++) {
                      this.eventsList.push(respData[i]);
                  }
              } else {
                  toastr.error(respData.message);
              }
              if (respData.length === 0) {
                  this.noEvents = true;
              }
          },
          (error) => {
            
              this.nao.go(100);
              window.localStorage.setItem('loader', 'false');
              console.log('error', error);
          }
      );
  }
  timeChanged(){
    this.selectedTime = new Date();

    this.selectedTime.setTime(this.timeModel.getTime());
    this.selectedTime.setDate(this.datepickerModel.getDate());
    this.selectedTime.setMonth(this.datepickerModel.getMonth());
    this.selectedTime.setFullYear(this.datepickerModel.getFullYear());

    // 2020-06-19 07:58:38

    this.selectedTimeUTC = this.selectedTime.getUTCFullYear()+'-'+(this.selectedTime.getMonth()+1).toString()+'-'+this.selectedTime.getDate()+
    ' ' + this.selectedTime.getUTCHours() + ':' + this.selectedTime.getUTCMinutes() + ':' + this.selectedTime.getUTCSeconds();
  }

  getTitleLength(e){
    var titleMax = 160;
    if (e.which < 0x20) {
      return;
    }
    if (this.message.length === titleMax) {
      e.preventDefault();
    } else if (this.message.length > titleMax) {
      this.message = this.message.substring(0, titleMax);
    }
  }

  upadteAge(filter, filterValue) {
    if ( filter === '0' ){
      this.age.disable = true;
      this.age.value = null;
    } else {
      this.age.disable = false;
    }
    this.age.filter = filter;
    this.age.filterValue = filterValue;
  }

  upadteJoinDate(filter, filterValue) {
    
    if ( filter === '0' ){
      this.joiningDate.disable = true;
      this.joiningDate.value = null;
    } else {
      this.joiningDate.disable = false;
    }
    this.joiningDate.filter = filter;
    this.joiningDate.filterValue = filterValue;
  }

  updateGender(filter, filterValue) {
    
    if ( filter === '0' ){
      this.gender.disable = true;
      this.gender.value = null;
    } else {
      this.gender.disable = false;
    }
    this.gender.filter = filter;
    this.gender.filterValue = filterValue;
  }

  updateProfileStatus(filter, filterValue) {
    
    if ( filter === '0' ){
      this.profileVerification.disable = true;
      this.profileVerification.value = null;
    } else {
      this.profileVerification.disable = false;
    }
    this.profileVerification.filter = filter;
    this.profileVerification.filterValue = filterValue;
  }

  validateAllInputs()
  {
      if (this.message === '')
      {
        this.errorMessage = '*Please enter a message';
        return false 

      }
      return true
  }

  createEvent()
  {
    this.errorMessage = ''
    var valid = this.validateAllInputs();
    if (valid == true)
    {
        this.createEventCall();
    }
  }

  async createEventCall() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');
    this.userListPageCount = this.userListPageCount + 1;

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    
    
    var joinDateSelected = ''
    if (this.joiningDate.value)
    {
      joinDateSelected = this.joiningDate.value.getUTCFullYear()+'-'+(this.joiningDate.value.getMonth()+1).toString()+'-'+this.joiningDate.value.getDate();
    }

    var age = 1;
    if (this.age.value)
    {
      age = this.age.value;
    }

    const body = {
      age: age,
      age_scale: this.age.filter,
      gender: [this.gender.filter],
      verified_status: this.profileVerification.filter, 
      joined_date: joinDateSelected,
      joined_date_scale: this.joiningDate.filter,
      event_type: this.eventType,
      event_message: this.message,
      event_scheduled_datetime: this.selectedTimeUTC
      };

      //last_page
        

    this.dataService.createEvent(this.token,JSON.stringify(body)).subscribe(
        (data) => {

            
            this.nao.go(100);
            window.localStorage.setItem('loader', 'false');
            var respData :any;
            respData = data;
            
            if (respData.is_success === true) {
              toastr.success('Event Scheduled Successfully!');
              $('#addEvent').modal('hide');
              this.getEvents();
            } else {
                toastr.error(respData.message);
            }
            
            
        },
        (error) => {
          
            this.nao.go(100);
            window.localStorage.setItem('loader', 'false');
            console.log('error', error);
        }
    );
}


  async getUsersList() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');
    this.userListPageCount = this.userListPageCount + 1;

    this.usersList = [];

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;
    
    var joinDateSelected = ''
    if (this.joiningDate.value)
    {
      joinDateSelected = this.joiningDate.value.getUTCFullYear()+'-'+(this.joiningDate.value.getMonth()+1).toString()+'-'+this.joiningDate.value.getDate();
    }
    var age = 1;
    if (this.age.value)
    {
      age = this.age.value;
    }

    const body = {
      age: age,
      age_scale: this.age.filter,
      gender: [this.gender.filter],
      verified_status: this.profileVerification.filter, 
      joined_date: joinDateSelected,
      joined_date_scale: this.joiningDate.filter,
      event_type: this.eventType,
      event_message: this.message,
      event_scheduled_datetime: this.selectedTimeUTC
      };

      //last_page
        

    this.dataService.getUsersList(this.token,JSON.stringify(body),this.userListPageCount).subscribe(
        (data) => {

            
            this.nao.go(100);
            window.localStorage.setItem('loader', 'false');
            var respData :any;
            respData = data;
            
            if (respData.is_success === true) {
              debugger;
              this.userListPageLast = respData.last_page
              this.usersTotalCount = respData.total_count;
              respData = respData.data;
                
                for (let i = 0; i < respData.length; i++) {
                    this.usersList.push(respData[i]);
                }
            } else {
                toastr.error(respData.message);
            }
            
            if (this.userListPageCount==1)
            {
              $('#customerList').modal('show');
            }
        },
        (error) => {
          
            this.nao.go(100);
            window.localStorage.setItem('loader', 'false');
            console.log('error', error);
        }
    );
}

getCustomerEventListPage()
  {
    
      
      this.getUsersList();
     
  }

  getCustomerEventList()
  {
    this.errorMessage = ''
    this.userListPageCount = 0
    var valid = this.validateAllInputs();
    if (valid == true)
    {
      
      this.getUsersList();
      
    }

  }

  

  callAddEventFun(){
    //Huge

    this.minDate = new Date();
        this.datepickerModel = new Date();
        this.timeModel = new Date();

        this.message = '';

        this.eventType = '2';


        this.age = {
          'value': null,
          'filter': '0',
          'filterValue': 'All',
          'disable': true
        };
    
        this.joiningDate = {
          'value': null,
          'filter': '0',
          'filterValue': 'All',
          'disable': true
        };

        this.gender = {
          'value': null,
          'filter': '0',
          'filterValue': 'Any',
          'disable': true
        };

        this.profileVerification = {
          'value': null,
          'filter': '0',
          'filterValue': 'Any',
          'disable': true
        };

        
    $('#addEvent').modal('show');

    
  }

  async deleteEvent() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;
    
    this.dataService.deleteEvent(this.token, this.itemToProcess).subscribe(
        (data) => {
          this.itemToProcess = -1
          this.eventsList[this.itemToProcessIndex].event_status = 2
          this.itemToProcessIndex = -1

          $('#DeletePopup').modal('hide');

          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
         
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }


  openDeleteEventPopup(id,index) {
    this.itemToProcess = id;
    this.itemToProcessIndex = index;
    $('#DeletePopup').modal('show');
  }

    onChangeRouterImage() {
        this.sharedService.sendRouterImageValue('none');
    }
}
