import { Component, OnInit } from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../data.service';
import {SharedService} from '../shared.service';
import {RefreshTokenService} from '../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;
declare var $: any;

@Component({
  selector: 'app-doubledate',
  templateUrl: './doubledate.component.html',
  styleUrls: ['./doubledate.component.css']
})
export class DoubledateComponent implements OnInit {

  nao = new Nanobar();
    usersData: any;
    userId: any;
    userDeleteData: any;
    tokenData: any;
    token: any;
    clearTimeout: any;
    searchString = '';
    page = 1;
    offset = 10;
    userList = [];
    noUsers = false;

    constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('doubleDate');
        this.getUsers();
    }

    async getUsers() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.userListDouble(this.token, this.searchString, 0, this.page, this.offset).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.usersData = data;
                if (this.usersData.is_success === true) {
                    this.usersData = this.usersData.data;
                    // debugger;
                    for (let i = 0; i < this.usersData.length; i++) {
                        this.userList.push(this.usersData[i]);
                    }
                } else {
                    toastr.error(this.usersData.message);
                }
                if (this.userList.length === 0) {
                    this.noUsers = true;
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    onChangeRouterImage() {
        this.sharedService.sendRouterImageValue('none');
    }

    openDeleteUserPopup(id) {
        this.userId = id;
        $('#userDeletePopup').modal('show');
    }

    async deleteUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.deleteUser(this.token, this.userId).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                $('#userDeletePopup').modal('hide');
                this.userDeleteData = data;
                if (this.userDeleteData.is_success === true) {
                    toastr.success(this.userDeleteData.message);
                    this.page = 1;
                    this.userList = [];
                    this.noUsers = false;
                    this.getUsers();
                } else {
                    toastr.error(this.userDeleteData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    onSearching(ev) {
        const val = ev.target.value;
        console.log('target value', val);
        clearTimeout(this.clearTimeout);
        this.clearTimeout = setTimeout(() => {
            this.page = 1;
            this.userList = [];
            this.searchString = val;
            this.getUsers();
        }, 750);
    }

    onLoadMore() {
        this.page = this.page + 1;
        this.getUsers();
    }

}
