import {
    Component,
    ElementRef,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    DataService
} from '../../data.service';
import {
    FormArray,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {
    SharedService
} from '../../shared.service';
import {
    RefreshTokenService
} from '../../refresh-token.service';

declare var $: any;
declare var toastr: any;
declare var Nanobar: any;
declare var moment: any;
declare var google: any;

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
    nao = new Nanobar();
    userId: any;
    userData: any;
    userEditForm: FormGroup;
    submitted = false;
    configurations: any;
    lookingFor = [];
    interests = [];
    ethnicity = [];
    bodyType = [];
    meetAt = [];
    location = {
        id: -1,
        latitude: 0,
        longitude: 0,
        zip: '',
        country: '',
        state: '',
        city: '',
        address: ''
    };
    place: any;
    userEditData: any;
    tokenData: any;
    token: any;
    allFiles = [];
    totalFiles = 6;
    fileData: any;
    videoUrl = '';
    accountName: any;
    accountID: any;
    name: any;
    countReserve = 0;
    countReserveBodyT= 0;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService, private elRef: ElementRef) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('none');

        this.activatedRoute.params.subscribe(params => {
            this.userId = params.id;
            this.getUser();
        });

        this.populateInitialImages();
        this.getAllConfigurations();
        this.initForm('init');
        this.inputAddress();
    }

    populateInitialImages() {
        for (let i = 0; i < this.totalFiles; i++) {
            this.allFiles.push({
                name: '',
                id: -1,
                isVideo: false,
                url: 'https://d2pk0scdvm6o1i.cloudfront.net/images/detailed/7/D91_Slate_Grey.jpg?t=1511287870',
                videoAvailable: false,
                videoScreenShotUrl: '',
                added: false
            });
        }
    }

    inputAddress() {
        const context = this;
        const input = document.getElementById('address');
        const searchBox = new google.maps.places.Autocomplete(input);
        console.log('inputAddress', searchBox);
        searchBox.addListener('place_changed', () => {
            console.log('place changed');
            context.place = searchBox.getPlace();
            // context.searchText = context.place.formatted_address;
            console.log('address', context.place);
            context.calculateLocation();
        });
    }

    calculateLocation() {
        for (let i = 0; i < this.place.address_components.length; i++) {
            for (let j = 0; j < this.place.address_components[i].types.length; j++) {
                if (this.place.address_components[i].types[j] === 'administrative_area_level_2') {
                    this.location.city = this.place.address_components[i].long_name;
                }

                if (this.place.address_components[i].types[j] === 'administrative_area_level_1') {
                    this.location.state = this.place.address_components[i].long_name;
                }

                if (this.place.address_components[i].types[j] === 'country') {
                    this.location.country = this.place.address_components[i].long_name;
                }

                if (this.place.address_components[i].types[j] === 'postal_code') {
                    this.location.zip = this.place.address_components[i].long_name;
                }

            }
        }

        this.location.latitude = this.place.geometry.location.lat();
        this.location.longitude = this.place.geometry.location.lng();
        this.location.address = this.place.formatted_address;
        this.userEditForm.value.address = this.place.formatted_address;
    }


    async getAllConfigurations() {

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.getAllConfigurations(this.token).subscribe(
            (data) => {
                // debugger;
                console.log('configurations data', data);
                this.configurations = data;
                if (this.configurations.is_success === true) {
                    this.configurations = this.configurations.data;
                }
            },
            (error) => {
                console.log('error', error);
            }
        );
    }

    async getUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');
        // this.getConfigurations();

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.getUser(this.token, this.userId).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.userData = data;
                if (this.userData.is_success === true) {
                    this.userData = this.userData.data;
                    this.initForm('edit');
                    this.populateImages();
                } else {
                    toastr.error(this.userData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    populateImages() {
        for (let i = 0; i < this.userData.profile.images.length; i++) {
            if (this.userData.profile.images[i].is_video === true) {
                this.allFiles[i].url = this.userData.profile.images[i].original_video_url;
            } else {
                this.allFiles[i].url = this.userData.profile.images[i].object_url;
            }
            this.allFiles[i].name = this.userData.profile.images[i].name;
            this.allFiles[i].id = this.userData.profile.images[i].id;
            this.allFiles[i].isVideo = this.userData.profile.images[i].is_video;
            this.allFiles[i].videoAvailable = this.userData.profile.images[i].video_available;
            this.allFiles[i].videoScreenShotUrl = this.userData.profile.images[i].video_screenshot_url;
            this.allFiles[i].added = true;
        }
    }


    initForm(data) {
        
        if (data === 'init') {
            this.userEditForm = new FormGroup({
                id: new FormControl(),
                name: new FormControl(),
                fullName: new FormControl(),
                email: new FormControl(),
                address: new FormControl(),
                phoneNo: new FormControl(),
                birthDate: new FormControl(),
                gender: new FormControl(),
                height: new FormControl(),
                weight: new FormControl(),
                relationshipStatus: new FormControl(),
                lookingFor: new FormControl(),
                about: new FormControl(),
                interests: new FormControl(),
                ethnicity : new FormControl(),
                bodyType: new FormControl(),
                meetAt: new FormControl(),
                images: new FormControl([]),
            });
        } else if (data === 'edit') {
            
            for (let i = 0; i < this.userData.profile.looking_for.length; i++) {
                this.lookingFor.push(this.userData.profile.looking_for[i].id);
            }
            for (let i = 0; i < this.userData.profile.interests.length; i++) {
                this.interests.push(this.userData.profile.interests[i].id);
            }
            for (let i = 0; i < this.userData.profile.meet_at.length; i++) {
                this.meetAt.push(this.userData.profile.meet_at[i].id);
            }
            for (let i = 0; i < this.userData.profile.ethnicity.length; i++) {
                this.ethnicity.push(this.userData.profile.ethnicity[i].id);
            }
            for (let i = 0; i < this.userData.profile.bodytype.length; i++) {
                this.bodyType.push(this.userData.profile.bodytype[i].id);
            }

            this.location.id = this.userData.profile.location.id;
            this.location.latitude = this.userData.profile.location.latitude;
            this.location.longitude = this.userData.profile.location.longitude;
            this.location.zip = this.userData.profile.location.zip;
            this.location.country = this.userData.profile.location.country;
            this.location.state = this.userData.profile.location.state;
            this.location.city = this.userData.profile.location.city;
            this.location.address = this.userData.profile.location.address;

            let dateBirth;
            if (this.userData.profile.date_of_birth === null) {
                dateBirth = '';
            } else {
                dateBirth = moment(this.userData.profile.date_of_birth).format('YYYY-MM-DD');
            }

            this.userEditForm = new FormGroup({
                id: new FormControl(this.userData.id),
                name: new FormControl(this.userData.first_name, Validators.required),
                fullName: new FormControl(this.userData.full_name, Validators.required),
                email: new FormControl(this.userData.email, Validators.required),
                address: new FormControl(this.userData.profile.location.address, Validators.required),
                phoneNo: new FormControl(this.userData.profile.phone_number, Validators.required),
                birthDate: new FormControl(dateBirth, Validators.required),
                gender: new FormControl(this.userData.profile.gender.id, Validators.required),
                height: new FormControl(this.userData.profile.height),
                weight: new FormControl(this.userData.profile.weight),
                relationshipStatus: new FormControl(this.userData.profile.relationship_status.id, Validators.required),
                lookingFor: new FormControl(this.lookingFor),
                about: new FormControl(this.userData.profile.about, Validators.required),
                interests: new FormControl(this.interests),
                ethnicity: new FormControl(this.ethnicity),
                bodyType: new FormControl(this.bodyType),
                meetAt: new FormControl(this.meetAt),
                images: new FormControl([]),
            });
        }

    }
    checkLookingForValue(data) {
        let check = false;
        // debugger;
        for (let i = 0; i < this.lookingFor.length; i++) {
            if (data.id === this.lookingFor[i]) {
                check = true;
                break;
            }
        }
        return check;
    }
    changeLookingForValue(data) {
        // debugger;
        let check = false;
        for (let i = 0; i < this.userEditForm.value.lookingFor.length; i++) {
            if (data.id === this.userEditForm.value.lookingFor[i]) {
                check = true;
                this.userEditForm.value.lookingFor.splice(i, 1);
            }
        }
        if (check === false) {
            this.userEditForm.value.lookingFor.push(data.id);
        }
    }
    checkInterestValue(data) {
        let check = false;
        for (let i = 0; i < this.interests.length; i++) {
            if (data.id === this.interests[i]) {
                check = true;
                break;
            }
        }
        return check;
    }
    checkEthnicityValue(data) {
        let check = false;
        for (let i = 0; i < this.ethnicity.length; i++) {
            if (data.id === this.ethnicity[i]) {
                check = true;
                break;
            }
        }
        return check;
    }
    changeEthnicityValue(data) {
       
        let check = false;
        for (let i = 0; i < this.userEditForm.value.ethnicity.length; i++) {
            if (data.id === this.userEditForm.value.ethnicity[i]) {
                check = true;
                this.userEditForm.value.ethnicity.splice(i, 1);
                
            }
        }
        if (check === false) {
            this.userEditForm.value.ethnicity.push(data.id);
        }
    }

    checkBodyTypeValue(data) {
        let check = false;
        for (let i = 0; i < this.bodyType.length; i++) {
            if (data.id === this.bodyType[i]) {
                check = true;
                break;
            }
        }
        return check;
    }
    changeBodyTypeValue(data) {

        let check = false;
        for (let i = 0; i < this.userEditForm.value.bodyType.length; i++) {
            if (data.id === this.userEditForm.value.bodyType[i]) {
                check = true;
                    this.userEditForm.value.bodyType.splice(i, 1);        
            }
        }
        if (check === false) {
            this.userEditForm.value.bodyType.push(data.id);
        }
    }

    checkMeetAtValue(data) {
        let check = false;
        for (let i = 0; i < this.meetAt.length; i++) {
            if (data.id === this.meetAt[i]) {
                check = true;
                break;
            }
        }
        return check;
    }
    changeMeetAtValue(data) {
        let check = false;
        for (let i = 0; i < this.userEditForm.value.meetAt.length; i++) {
            if (data.id === this.userEditForm.value.meetAt[i]) {
                check = true;
                this.userEditForm.value.meetAt.splice(i, 1);
            }
        }
        if (check === false) {
            this.userEditForm.value.meetAt.push(data.id);
        }
    }

    changeInterestValue(data) {
        let check = false;
        for (let i = 0; i < this.userEditForm.value.interests.length; i++) {
            if (data.id === this.userEditForm.value.interests[i]) {
                check = true;
                this.userEditForm.value.interests.splice(i, 1);
            }
        }
        if (check === false) {
            this.userEditForm.value.interests.push(data.id);
        }
    }

    // convenience getter for easy access to form fields
    get field() {
        return this.userEditForm.controls;
    }

    async onEditUser() {
        this.submitted = true;
        console.log('location: ', this.location);
        console.log('addModelForm: ', this.userEditForm.value);
        const body = {
            user_name: this.userEditForm.value.name,
            birthday: moment.utc(this.userEditForm.value.birthDate).format(),
            weight: parseInt(this.userEditForm.value.weight),
            about: this.userEditForm.value.about,
            height: parseInt(this.userEditForm.value.height),
            gender: parseInt(this.userEditForm.value.gender),
            relationship_status: parseInt(this.userEditForm.value.relationshipStatus),
            looking_for: this.userEditForm.value.lookingFor,
            interests: this.userEditForm.value.interests,
            ethnicity: this.userEditForm.value.ethnicity,
            bodyType: this.userEditForm.value.bodyType,
            meetAt: this.userEditForm.value.meetAt,
            phone_number: this.userEditForm.value,
            accountName: this.accountName,
            accountID: this.accountID

        };
        console.log('editUser API body data',body);
        if (this.userEditForm.invalid || this.userEditForm.value.lookingFor.length === 0 || this.userEditForm.value.interests.length === 0 || this.userEditForm.value.ethnicity.length === 0 || this.userEditForm.value.bodyType.length === 0 || this.userEditForm.value.meetAt.length === 0) {
            toastr.error('Some fields missing');
            return;
        }

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;
        this.dataService.updateLocation(this.token, this.userId, JSON.stringify(this.location)).subscribe(
            (data) => {
                console.log('location data', data);
            },
            (error) => {
                console.log('error', error);
            }
        );

        this.nao.go(30);
        this.dataService.editUser(this.token, this.userId, JSON.stringify(body)).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.userEditData = data;
                if (this.userEditData.is_success === true) {
                    toastr.success(this.userEditData.message);
                    this.router.navigate(['/user/', this.userId]);
                } else {
                    toastr.error(this.userEditData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }
    onAddSocialAcc(){
        $('#socialLinksModal').modal('show');
    }
    closeSocialAccModal(){
        this.accountName = this.accountName;
        this.accountID=this.accountID;
        $('#socialLinksModal').modal('hide');
    }

    fileUpload(file, index) {
        console.log('file: ', file.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(file.target.files[0]);
        reader.onload = () => {
            console.log('result in base 64', reader.result);
            if (file.target.files[0].type.match(/video.*/)) {
                const video = document.createElement('video');
                const canvas = document.createElement('canvas');
                video.preload = 'metadata';

                video.onloadedmetadata = () => {

                    window.URL.revokeObjectURL(video.src);
                    console.log('duration: ', video.duration);
                    if (video.duration > 60) {
                        toastr.error('Video duration is 1 minute only');
                        return;
                    }
                    console.log('height: ', video.videoHeight);
                    console.log('width: ', video.videoWidth);

                    if (this.allFiles[index].added === true) {

                        this.nao.go(30);
                        window.localStorage.setItem('loader', 'true');
                        this.dataService.addVideo(this.token, this.userId, file.target.files[0], index + 1, video.videoWidth, video.videoHeight).subscribe(
                            (data) => {
                                console.log('add video data', data);
                                this.fileData = data;
                                this.fileData = this.fileData.data;
                                this.allFiles[index].name = this.fileData.name;
                                this.allFiles[index].id = this.fileData.id;
                                this.allFiles[index].isVideo = this.fileData.is_video;
                                this.allFiles[index].url = this.fileData.object_url;
                                this.allFiles[index].videoAvailable = this.fileData.video_available;
                                this.allFiles[index].videoScreenShotUrl = this.fileData.video_screenshot_url;
                                this.allFiles[index].added = true;
                                this.nao.go(100);
                                window.localStorage.setItem('loader', 'false');
                            },
                            (error) => {
                                this.nao.go(100);
                                window.localStorage.setItem('loader', 'false');
                                console.log('error', error);
                            }
                        );
                    } else {

                        let i = 0;
                        for (i; i < this.totalFiles; i++) {
                            if (this.allFiles[i].added === false) {
                                break;
                            }
                        }

                        this.nao.go(30);
                        window.localStorage.setItem('loader', 'true');
                        this.dataService.addVideo(this.token, this.userId, file.target.files[0], i + 1, video.videoWidth, video.videoHeight).subscribe(
                            (data) => {
                                console.log('add video data', data);
                                this.fileData = data;
                                this.fileData = this.fileData.data;
                                i = 0;
                                for (i; i < this.totalFiles; i++) {
                                    if (this.allFiles[i].added === false) {
                                        this.allFiles[i].name = this.fileData.name;
                                        this.allFiles[i].id = this.fileData.id;
                                        this.allFiles[i].isVideo = this.fileData.is_video;
                                        this.allFiles[i].url = this.fileData.object_url;
                                        this.allFiles[i].videoAvailable = this.fileData.video_available;
                                        this.allFiles[i].videoScreenShotUrl = this.fileData.video_screenshot_url;
                                        this.allFiles[i].added = true;
                                        break;
                                    }
                                }
                                this.nao.go(100);
                                window.localStorage.setItem('loader', 'false');
                            },
                            (error) => {
                                this.nao.go(100);
                                window.localStorage.setItem('loader', 'false');
                                console.log('error', error);
                            }
                        );
                    }

                };

                video.src = URL.createObjectURL(file.target.files[0]);

            } else {
                if (this.allFiles[index].added === true) {
                    this.allFiles[index].url = reader.result;

                    this.nao.go(30);
                    window.localStorage.setItem('loader', 'true');
                    this.dataService.addImage(this.token, this.userId, file.target.files[0], index + 1).subscribe(
                        (data) => {
                            console.log('add image data', data);
                            this.fileData = data;
                            this.fileData = this.fileData.data;
                            this.allFiles[index].name = this.fileData.name;
                            this.allFiles[index].id = this.fileData.id;
                            this.allFiles[index].isVideo = this.fileData.is_video;
                            this.allFiles[index].url = this.fileData.object_url;
                            this.allFiles[index].videoAvailable = this.fileData.video_available;
                            this.allFiles[index].videoScreenShotUrl = this.fileData.video_screenshot_url;
                            this.allFiles[index].added = true;
                            this.nao.go(100);
                            window.localStorage.setItem('loader', 'false');
                        },
                        (error) => {
                            this.nao.go(100);
                            window.localStorage.setItem('loader', 'false');
                            console.log('error', error);
                        }
                    );

                } else {
                    let i = 0;
                    for (i; i < this.totalFiles; i++) {
                        if (this.allFiles[i].added === false) {
                            break;
                        }
                    }

                    this.nao.go(30);
                    window.localStorage.setItem('loader', 'true');
                    this.dataService.addImage(this.token, this.userId, file.target.files[0], i + 1).subscribe(
                        (data) => {
                            console.log('add image data', data);
                            this.fileData = data;
                            this.fileData = this.fileData.data;
                            i = 0;
                            for (i; i < this.totalFiles; i++) {
                                if (this.allFiles[i].added === false) {
                                    this.allFiles[i].name = this.fileData.name;
                                    this.allFiles[i].id = this.fileData.id;
                                    this.allFiles[i].isVideo = this.fileData.is_video;
                                    this.allFiles[i].url = this.fileData.object_url;
                                    this.allFiles[i].videoAvailable = this.fileData.video_available;
                                    this.allFiles[i].videoScreenShotUrl = this.fileData.video_screenshot_url;
                                    this.allFiles[i].added = true;
                                    break;
                                }
                            }
                            this.nao.go(100);
                            window.localStorage.setItem('loader', 'false');
                        },
                        (error) => {
                            this.nao.go(100);
                            window.localStorage.setItem('loader', 'false');
                            console.log('error', error);
                        }
                    );
                }
            }

        };
    }

    removeImage(index) {

        console.log('file to remove', this.allFiles[index]);
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');
        this.dataService.deleteImage(this.token, this.userId, this.allFiles[index].id).subscribe(
            (data) => {
                console.log('delete image data', data);
                for (let i = index; i < this.totalFiles - 1; i++) {
                    this.allFiles[i] = this.allFiles[i + 1];
                }
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
            },
            (error) => {
                console.log('error', error);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
            }
        );

        console.log('allFiles: ', this.allFiles);
    }

    onPlayVideo(index) {
        this.videoUrl = this.allFiles[index].url;
        const player = this.elRef.nativeElement.querySelector('video');
        player.load();
        $('#showVideo').modal('show');
    }
    sendSocialLinkID(num){
        this.accountID = num;
    }
    showMediumName(val){
        this.name = val;
    }
}