import {Component, OnInit} from '@angular/core';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RefreshTokenService} from '../../refresh-token.service';

declare var google: any;
declare var toastr: any;
declare var Nanobar: any;

@Component({
    selector: 'app-admin-edit',
    templateUrl: './admin-edit.component.html',
    styleUrls: ['./admin-edit.component.css']
})
export class AdminEditComponent implements OnInit {

    nao = new Nanobar();
    admin: any = {name: '', emailAddress: '', phoneNumber: '', address: ''};
    adminId: any;
    adminData: any;
    location = {
        latitude: 0,
        longitude: 0,
        zip: '',
        country: '',
        state: '',
        city: '',
        address: ''
    };
    place: any;
    adminEditData: any;
    tokenData: any;
    token: any;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('none');
        this.activatedRoute.params.subscribe(params => {
            this.adminId = params.id;
            this.getAdmin();
        });
        this.inputAddress();
    }

    async getAdmin() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.getAdmin(this.token, this.adminId).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.adminData = data;
                if (this.adminData.is_success === true) {
                    this.adminData = this.adminData.data;
                    this.admin.name = this.adminData.first_name;
                    this.admin.emailAddress = this.adminData.email;
                    this.admin.name = this.adminData.first_name;
                    this.admin.phoneNumber = this.adminData.profile.phone_number;

                    this.location.latitude = this.adminData.profile.location.latitude;
                    this.location.longitude = this.adminData.profile.location.longitude;
                    this.location.zip = this.adminData.profile.location.zip;
                    this.location.country = this.adminData.profile.location.country;
                    this.location.state = this.adminData.profile.location.state;
                    this.location.city = this.adminData.profile.location.city;
                    this.location.address = this.adminData.profile.location.address;

                    this.admin.address = this.adminData.profile.location.address;
                } else {
                    toastr.error(this.adminData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    inputAddress() {
        const context = this;
        const input = document.getElementById('address');
        const searchBox = new google.maps.places.Autocomplete(input);
        console.log('inputAddress', searchBox);
        searchBox.addListener('place_changed', () => {
            console.log('place changed');
            context.place = searchBox.getPlace();
            // context.searchText = context.place.formatted_address;
            console.log('address', context.place);
            context.calculateLocation();
        });
    }

    calculateLocation() {
        for (let i = 0; i < this.place.address_components.length; i++) {
            for (let j = 0; j < this.place.address_components[i].types.length; j++) {
                if (this.place.address_components[i].types[j] === 'administrative_area_level_2') {
                    this.location.city = this.place.address_components[i].long_name;
                }

                if (this.place.address_components[i].types[j] === 'administrative_area_level_1') {
                    this.location.state = this.place.address_components[i].long_name;
                }

                if (this.place.address_components[i].types[j] === 'country') {
                    this.location.country = this.place.address_components[i].long_name;
                }

                if (this.place.address_components[i].types[j] === 'postal_code') {
                    this.location.zip = this.place.address_components[i].long_name;
                }

            }
        }

        this.location.latitude = this.place.geometry.location.lat();
        this.location.longitude = this.place.geometry.location.lng();
        this.location.address = this.place.formatted_address;
    }

    async onEditAdmin() {
        if (this.location.zip === undefined) {
            this.location.zip = '';
        }
        const body = {
            email: this.admin.emailAddress,
            password: this.admin.password,
            full_name: this.admin.name,
            number: this.admin.phoneNumber,
            address: this.location
        };

        this.nao.go(30);

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.editAdmin(this.token, this.adminId, JSON.stringify(body)).subscribe(
            (data) => {
                console.log('admin edit data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.adminEditData = data;
                if (this.adminEditData.is_success === true) {
                    this.adminEditData = this.adminEditData.data;
                    this.router.navigate(['/admin/', this.adminId]);
                } else {
                    toastr.error(this.adminEditData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }


}
