import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../data.service';
import {RefreshTokenService} from '../../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;

@Component({
    selector: 'app-admin-view',
    templateUrl: './admin-view.component.html',
    styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent implements OnInit {

    nao = new Nanobar();
    admin: any = {name: '', emailAddress: '', phoneNumber: '', address: '', password: '', confirmPassword: ''};
    adminId: any;
    adminData: any;
    tokenData: any;
    token: any;
    passwordData: any;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('none');
        this.activatedRoute.params.subscribe(params => {
            this.adminId = params.id;
            this.getAdmin();
        });
    }

    async getAdmin() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.getAdmin(this.token, this.adminId).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.adminData = data;
                if (this.adminData.is_success === true) {
                    this.adminData = this.adminData.data;
                } else {
                    toastr.error(this.adminData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    onChangeRouterImage() {
        this.sharedService.sendRouterImageValue('none');
    }

    async onUpdatePassword() {
        const body = {
            password: this.admin.password,
        };

        this.nao.go(30);

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.changePasswordAdmin(this.token, this.adminId, JSON.stringify(body)).subscribe(
            (data) => {
                console.log('change password data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.passwordData = data;
                if (this.passwordData.is_success === true) {
                    toastr.success(this.passwordData.message);
                } else {
                    toastr.error(this.passwordData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

}
