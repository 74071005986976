import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../data.service';
import {SharedService} from '../shared.service';
import {RefreshTokenService} from '../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;
declare var $: any;

@Component({
  selector: 'app-spam-media',
  templateUrl: './spam-media.component.html',
  styleUrls: ['./spam-media.component.css']
})
export class SpamMediaComponent implements OnInit {
  
  spamsData: any;
  api_data:any;
  token: any;
  tokenData: any;
  userId: any;
  clearTimeout: any;
  nao = new Nanobar();
  page = 1;
  offset = 50;
  loadMore = false

  itemToProcess = -1;
  itemToProcessIndex = -1;
  filterValue: any;
  filterFlagAll = false;
  filterFlagPending = false;
  filterFlagRejected = false;
  constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
    this.token = window.localStorage.getItem('token');
  }

  ngOnInit() {
    this.spamsData = null
    this.filterFlagAll = true
    this.filterValue = 0
    this.getSpams();
  }
  onLoadMore() {
    this.page = this.page + 1;
    this.getSpams();
  }

  openDeleteMediaPopup(id,index) {
    this.itemToProcess = id;
    this.itemToProcessIndex = index;
    $('#DeletePopup').modal('show');
  }

  openApproveMediaPopup(id,index) {
    this.itemToProcess = id;
    this.itemToProcessIndex = index;
    $('#ApprovePopup').modal('show');
  }

  async deleteMedia() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;
    
    this.dataService.deleteMedia(this.token, this.itemToProcess).subscribe(
        (data) => {
          this.itemToProcess = -1
          this.spamsData[this.itemToProcessIndex].admin_decision = 2
          this.itemToProcessIndex = -1

          $('#DeletePopup').modal('hide');

          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
         
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

  async approveMedia() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;
    
    this.dataService.approveMedia(this.token, this.itemToProcess).subscribe(
        (data) => {

          this.itemToProcess = -1
          this.spamsData[this.itemToProcessIndex].admin_decision = 1
          this.itemToProcessIndex = -1

          $('#ApprovePopup').modal('hide');

          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
         
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }
filterSpamMedia(id){
  this.filterValue = id;
  if(this.filterValue == 0){
    this.filterFlagAll = true;
    this.filterFlagRejected = false;
    this.filterFlagPending = false; 
    this.spamsData = [];
    this.getSpams();
  }
  if(this.filterValue == 2){
    
    this.filterFlagRejected = true;
    this.filterFlagAll = false;
    this.filterFlagPending = false;
    this.spamsData = [];
    this.getSpams();
  }
  if(this.filterValue == 3){
    
    this.filterFlagPending = true;
    this.filterFlagRejected = false;
    this.filterFlagAll = false;
    this.spamsData = [];
    this.getSpams();
  }
}



  async getSpams() {
    this.filterValue;
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.spamListMedia(this.token,this.page, this.offset).subscribe(
        (data) => {
          if (this.spamsData == null)
          {
            this.spamsData = []
          }
          console.log('spams data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          this.api_data = data;
          if (this.api_data.is_success === true) {

            if (this.api_data.data.length == this.offset)
            {
              this.loadMore = true
            }
            else{
              this.loadMore = false
            }

            if (this.filterValue == 0)
            {
              for (let i = 0; i <  this.api_data.data.length; i++) {
                this.spamsData.push( this.api_data.data[i]);
              }
            }
            else if (this.filterValue == 2)
            {
              for (let i = 0; i <  this.api_data.data.length; i++) {
                if(this.api_data.data[i].admin_decision == 2)
                {
                  this.spamsData.push( this.api_data.data[i]);
                }
              }
            }
            else if (this.filterValue == 3)
            {
              for (let i = 0; i <  this.api_data.data.length; i++) {
                if(this.api_data.data[i].admin_decision == 0)
                {
                  this.spamsData.push( this.api_data.data[i]);
                }
              }
            }

            
            
              // for (let i = 0; i <  this.api_data.data.length; i++) {
              //   if(this.api_data.data[i].admin_decision == this.filterValue){
              //     this.spamsData.push( this.api_data.data[i]);
              //   }
              // }
              // if(this.filterValue != 2 && this.filterValue != 3){
              //   for (let i = 0; i <  this.api_data.data.length; i++) {
              //   this.spamsData.push( this.api_data.data[i]);
              // }
              // }

         
            localStorage.setItem('spamsMedia', JSON.stringify(this.spamsData));

          } else {
            toastr.error(this.spamsData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

}


