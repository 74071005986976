import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private routerImage = new Subject<any>();

  constructor() { }

  sendRouterImageValue(data: any) {
    this.routerImage.next({value: data});
  }

  getRouterImageValue(): Observable<any> {
    return this.routerImage.asObservable();
  }
}
