import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {RefreshTokenService} from '../../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;

@Component({
    selector: 'app-user-add',
    templateUrl: './user-add.component.html',
    styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {
    nao = new Nanobar();
    user: any = {name: '', fullName : '', emailAddress: '', phoneNumber: '', password: '', confirmPassword: ''};
    userAddData: any;
    tokenData: any;
    token: any;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('none');
    }

    async onAddUser() {
        const body = {
            email: this.user.emailAddress,
            password: this.user.password,
            full_name: this.user.name,
            app_full_name: this.user.fullName,
            number: this.user.phoneNumber
        };

        this.nao.go(30);

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.addUser(this.token, JSON.stringify(body)).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.userAddData = data;
                if (this.userAddData.is_success === true) {
                    this.userAddData = this.userAddData.data;
                    this.router.navigate(['/user/', this.userAddData.id, 'edit']);
                } else {
                    toastr.error(this.userAddData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

}
