import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './Common/login/login.component';
import {DashboardComponent} from './Common/dashboard/dashboard.component';
import {ForgotPasswordComponent} from './Common/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './Common/reset-password/reset-password.component';
import {UserListComponent} from './User/user-list/user-list.component';
import {AdminListComponent} from './Admin/admin-list/admin-list.component';
import {AdminAddComponent} from './Admin/admin-add/admin-add.component';
import {AdminViewComponent} from './Admin/admin-view/admin-view.component';
import {AdminEditComponent} from './Admin/admin-edit/admin-edit.component';
import {UserDetailComponent} from './User/user-detail/user-detail.component';
import {UserEditComponent} from './User/user-edit/user-edit.component';
import {UserAddComponent} from './User/user-add/user-add.component';
import {AuthGuardService} from './auth-guard.service';
import {SpamListComponent} from './Common/spam-list/spam-list.component';
import {SpamDetailComponent} from './Common/spam-detail/spam-detail.component';
import {ProfileAttrComponent} from './profileAttributes/profile-attr/profile-attr.component';
import { GenderComponent } from './profileAttributes/gender/gender.component';
import { EthnicityComponent } from './profileAttributes/ethnicity/ethnicity.component';
import { LookingForComponent } from './profileAttributes/looking-for/looking-for.component';
import { RelationshipStatusComponent } from './profileAttributes/relationship-status/relationship-status.component';
import { MeetAtComponent } from './profileAttributes/meet-at/meet-at.component';
import { SpamMediaComponent } from './spam-media/spam-media.component';
import {DoubledateComponent} from './doubledate/doubledate.component';
import {MessageComponent} from './message/message.component';
import {DoubleUserComponent} from './double-user/double-user.component';
import {DateNightComponent} from './date-night/date-night.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'admins', component: AdminListComponent, canActivate: [AuthGuardService]},
  {path: 'admin/add', component: AdminAddComponent, canActivate: [AuthGuardService]},
  {path: 'admin/:id', component: AdminViewComponent, canActivate: [AuthGuardService]},
  {path: 'admin/:id/edit', component: AdminEditComponent, canActivate: [AuthGuardService]},
  {path: 'users', component: UserListComponent, canActivate: [AuthGuardService]},
  {path: 'doubledate', component: DoubledateComponent, canActivate: [AuthGuardService]},
  {path: 'user/add', component: UserAddComponent, canActivate: [AuthGuardService]},
  {path: 'user/:id', component: UserDetailComponent, canActivate: [AuthGuardService]},
  {path: 'user/:id/edit', component: UserEditComponent, canActivate: [AuthGuardService]},
  {path: 'spams', component: SpamListComponent, canActivate: [AuthGuardService]},
  {path: 'spam-media', component: SpamMediaComponent, canActivate: [AuthGuardService]},
  {path: 'spam/:id', component: SpamDetailComponent, canActivate: [AuthGuardService]},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuardService]}, //added  canActivate: [AuthGuardService] for API
  {path: 'profile-attributes', component: ProfileAttrComponent},
  {path: 'gender', component: GenderComponent, canActivate: [AuthGuardService]},
  {path: 'ethnicity', component: EthnicityComponent},
  {path: 'looking-for', component: LookingForComponent, canActivate: [AuthGuardService]},
  {path: 'relation-status', component: RelationshipStatusComponent, canActivate: [AuthGuardService]},
  {path: 'meet-at', component: MeetAtComponent, canActivate: [AuthGuardService]},
  {path: 'message', component: MessageComponent, canActivate: [AuthGuardService]},
  {path: 'double-profile/:id', component: DoubleUserComponent, canActivate: [AuthGuardService]},
  {path: 'datenight', component: DateNightComponent, canActivate: [AuthGuardService]},






  {path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
