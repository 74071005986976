import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {RefreshTokenService} from '../../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;
declare var $: any;

@Component({
  selector: 'app-spam-detail',
  templateUrl: './spam-detail.component.html',
  styleUrls: ['./spam-detail.component.css']
})
export class SpamDetailComponent implements OnInit {
  token: any;
  nao = new Nanobar();
  userId: any;
  spamData: any;
  tokenData: any;
  userBlockData: any;
  userDeleteData: any;
  isActive: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
    this.token = window.localStorage.getItem('token');
  }

  ngOnInit() {
    this.sharedService.sendRouterImageValue('none');
    this.activatedRoute.params.subscribe(params => {
      this.userId = params.id;
      this.getSpam();
    });
  }

  getSpam() {
    const spams = JSON.parse(localStorage.getItem('spams'));
    for (let i = 0; i < spams.length; i++) {
      if (parseInt(spams[i].id) === parseInt(this.userId)) {
        this.spamData = spams[i];
        console.log('spam', this.spamData);
        this.isActive = spams[i].is_active;
        break;
      }
    }
  }

  openDeleteUserPopup(id) {
    this.userId = id;
    $('#userDeletePopup').modal('show');
  }

  openBlockUserPopup(id) {
    this.userId = id;
    $('#userBlockPopup').modal('show');
  }

  async deleteUser() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.deleteUser(this.token, this.userId).subscribe(
        (data) => {
          console.log('deleteUser data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          $('#userDeletePopup').modal('hide');
          this.userDeleteData = data;
          if (this.userDeleteData.is_success === true) {
            toastr.success(this.userDeleteData.message);
            this.router.navigate(['/users']);
          } else {
            toastr.error(this.userDeleteData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

  async onBlockUser() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    const body = {
      user_id: this.userId
    };

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.blockUser(this.token, JSON.stringify(body)).subscribe(
        (data) => {
          console.log('blockUser data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          $('#userBlockPopup').modal('hide');
          this.userBlockData = data;
          if (this.userBlockData.is_success === true) {
            toastr.success('User is blocked successfully');
            this.isActive = false;
          } else {
            toastr.error(this.userBlockData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

  async onUnblockUser() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.unblockUser(this.token, this.userId).subscribe(
        (data) => {
          console.log('userunBlockData data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          this.userBlockData = data;
          if (this.userBlockData.is_success === true) {
            toastr.success('User is unblocked successfully');
            this.isActive = true;
          } else {
            toastr.error(this.userBlockData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

}
