import { Component, OnInit } from '@angular/core';

declare var toastr: any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  newPassword = '';
  confirmPassword = '';

  constructor() { }

  ngOnInit() {
  }

  resetPassword() {
    if (this.newPassword === '') {
      toastr.error('Please enter your new password');
      return;
    }
    if (this.confirmPassword === '') {
      toastr.error('Please enter your confirm password');
      return;
    }
    if (this.newPassword !== this.confirmPassword) {
      toastr.error('Both Password don\'t match');
      return;
    }
  }

}
