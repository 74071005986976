import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../data.service';

declare var toastr: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    email = '';
    password = '';
    publicData: any;
    loginData: any;

    constructor(private router: Router, private dataService: DataService) {
        if (window.localStorage.getItem('isAuthenticated') === 'true') {
            router.navigate(['dashboard']);
        }
    }

    ngOnInit() {
    }

    loginFunction() {
        if (this.email === '') {
            toastr.error('Please enter your email');
            return;
        }
        if (this.password === '') {
            toastr.error('Please enter your password');
            return;
        }
        window.localStorage.setItem('loader', 'true');

        this.dataService.publicToken().subscribe(
            (result) => {
                console.log('publicToken data', result);
                this.publicData = result;
                this.dataService.adminLogin(this.publicData.access_token, this.email, this.password).subscribe(
                    (data) => {
                        window.localStorage.setItem('loader', 'false');
                        console.log('login data', data);
                        this.loginData = data;
                        if (this.loginData.is_success === true) {
                            this.loginData = this.loginData.data;
                            window.localStorage.setItem('isAuthenticated', 'true');
                            window.localStorage.setItem('token', this.loginData.tokens.access_token);
                            const date = new Date();
                            const milliseconds = date.getTime();
                            // tslint:disable-next-line:no-bitwise
                            const currentTime = milliseconds / 1000 | 0;
                            const expiresOn = currentTime + this.loginData.tokens.expires_in;
                            window.localStorage.setItem('expiresOn', expiresOn);
                            window.localStorage.setItem('refreshToken', this.loginData.tokens.refresh_token);
                            window.localStorage.setItem('adminProfile', JSON.stringify(this.loginData.user));
                            this.router.navigate(['dashboard']);
                        } else {
                            toastr.error(this.loginData.message);
                        }
                    },
                    (error) => {
                        window.localStorage.setItem('loader', 'false');
                        toastr.error('Email or Password is incorrect');
                        console.log('error', error);
                    }
                );
            },
            (error) => {
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );

    }

}
