import { Component, OnInit } from '@angular/core';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RefreshTokenService} from '../../refresh-token.service';

declare var google: any;
declare var toastr: any;
declare var Nanobar: any;
@Component({
  selector: 'app-admin-add',
  templateUrl: './admin-add.component.html',
  styleUrls: ['./admin-add.component.css']
})
export class AdminAddComponent implements OnInit {

  nao = new Nanobar();
  admin = {name: '', emailAddress: '', phoneNumber: '', address: '', password: '', confirmPassword: ''};
  location = {
    latitude: 0,
    longitude: 0,
    zip: '',
    country: '',
    state: '',
    city: '',
    address: ''
  };
  place: any;
  adminAddData: any;
  tokenData: any;
  token: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
    this.token = window.localStorage.getItem('token');
  }

  ngOnInit() {
    this.sharedService.sendRouterImageValue('none');
    this.inputAddress();
  }

  inputAddress() {
    const context = this;
    const input = document.getElementById('address');
    const searchBox = new google.maps.places.Autocomplete(input);
    console.log('inputAddress', searchBox);
    searchBox.addListener('place_changed', () => {
      console.log('place changed');
      context.place = searchBox.getPlace();
      // context.searchText = context.place.formatted_address;
      console.log('address', context.place);
      context.calculateLocation();
    });
  }

  calculateLocation() {
    for (let i = 0; i < this.place.address_components.length; i++) {
      for (let j = 0; j < this.place.address_components[i].types.length; j++) {
        if (this.place.address_components[i].types[j] === 'administrative_area_level_2') {
          this.location.city = this.place.address_components[i].long_name;
        }

        if (this.place.address_components[i].types[j] === 'administrative_area_level_1') {
          this.location.state = this.place.address_components[i].long_name;
        }

        if (this.place.address_components[i].types[j] === 'country') {
          this.location.country = this.place.address_components[i].long_name;
        }

        if (this.place.address_components[i].types[j] === 'postal_code') {
          this.location.zip = this.place.address_components[i].long_name;
        }

      }
    }

    this.location.latitude = this.place.geometry.location.lat();
    this.location.longitude = this.place.geometry.location.lng();
    this.location.address = this.place.formatted_address;
  }


  async onAddAdmin() {
    const body = {
      email: this.admin.emailAddress,
      password: this.admin.password,
      full_name: this.admin.name,
      number: this.admin.phoneNumber,
      address: this.location
    };

    this.nao.go(30);

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.addAdmin(this.token, JSON.stringify(body)).subscribe(
        (data) => {
          console.log('users data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          this.adminAddData = data;
          if (this.adminAddData.is_success === true) {
            this.adminAddData = this.adminAddData.data;
            this.router.navigate(['/admin/', this.adminAddData.id, 'edit']);
          } else {
            toastr.error(this.adminAddData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

}
