import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './Common/sidebar/sidebar.component';
import { LoginComponent } from './Common/login/login.component';
import { HeaderComponent } from './Common/header/header.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DashboardComponent } from './Common/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './Common/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './Common/reset-password/reset-password.component';
import { AdminListComponent } from './Admin/admin-list/admin-list.component';
import { UserListComponent } from './User/user-list/user-list.component';
import { AdminAddComponent } from './Admin/admin-add/admin-add.component';
import { AdminEditComponent } from './Admin/admin-edit/admin-edit.component';
import { AdminViewComponent } from './Admin/admin-view/admin-view.component';
import { UserEditComponent } from './User/user-edit/user-edit.component';
import { UserDetailComponent } from './User/user-detail/user-detail.component';
import { UserAddComponent } from './User/user-add/user-add.component';
import { SpamListComponent } from './Common/spam-list/spam-list.component';
import { SpamDetailComponent } from './Common/spam-detail/spam-detail.component';
import { ProfileAttrComponent } from './profileAttributes/profile-attr/profile-attr.component';
import { GenderComponent } from './profileAttributes/gender/gender.component';
import { LookingForComponent } from './profileAttributes/looking-for/looking-for.component';
import { EthnicityComponent } from './profileAttributes/ethnicity/ethnicity.component';
import { RelationshipStatusComponent } from './profileAttributes/relationship-status/relationship-status.component';
import { MeetAtComponent } from './profileAttributes/meet-at/meet-at.component';
import { SpamMediaComponent } from './spam-media/spam-media.component';
import { ChartModule } from 'angular2-chartjs';
import { DoubledateComponent } from './doubledate/doubledate.component';
import { MessageComponent } from './message/message.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DoubleUserComponent } from './double-user/double-user.component';
import { DateNightComponent } from './date-night/date-night.component';



@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    LoginComponent,
    HeaderComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AdminListComponent,
    UserListComponent,
    AdminAddComponent,
    AdminEditComponent,
    AdminViewComponent,
    UserEditComponent,
    UserDetailComponent,
    UserAddComponent,
    SpamListComponent,
    SpamDetailComponent,
    ProfileAttrComponent,
    GenderComponent,
    LookingForComponent,
    EthnicityComponent,
    RelationshipStatusComponent,
    MeetAtComponent,
    SpamMediaComponent,
    DoubledateComponent,
    MessageComponent,
    DoubleUserComponent,
    DateNightComponent,
  
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    TimepickerModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
