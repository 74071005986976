import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from './data.service';

@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {

    token: any;
    refreshToken: any;
    refreshTokenData: any;
    expiresOn: any;
    publicData: any;

    constructor(private router: Router, private dataService: DataService) {
        this.token = window.localStorage.getItem('token');
        this.refreshToken = window.localStorage.getItem('refreshToken');
        this.expiresOn = window.localStorage.getItem('expiresOn');
    }

    async checkRefreshToken() {

        await this.asyncCallStorage();

        const date = new Date();
        const milliseconds = date.getTime();
        // tslint:disable-next-line:no-bitwise
        const currentTime = milliseconds / 1000 | 0;
        console.log('current seconds', currentTime);
        console.log('expire_on seconds', this.expiresOn);
        if (currentTime < this.expiresOn) {
            console.log('refresh is not called');
            return new Promise((resolve) => {
                resolve({token: this.token, refresh_token: this.refreshToken});
            });
        } else {
            console.log('refresh is called');
            return new Promise(resolve => {
                const result = this.asyncCallRefreshToken();
                resolve(result);
            });
        }

    }

    async asyncCallRefreshToken() {
        const result = await this.onRefreshToken();
        console.log('asyncCallRefreshToken', result);
        return result;
    }

    async asyncCallStorage() {
        const result = await this.getStorage();
        console.log('asyncCallStorage', result);
        return result;
    }

    getStorage() {
        return new Promise(resolve => {
            this.expiresOn = window.localStorage.getItem('expiresOn');
            setTimeout(() => resolve(), 1000);
        });
    }

    onRefreshToken() {

        const body = {
            refresh_token: window.localStorage.getItem('refreshToken')
        };

        return new Promise(resolve => {

            this.dataService.publicToken().subscribe(
                (result) => {
                    console.log('publicToken data', result);
                    this.publicData = result;
                    this.dataService.refreshToken(this.publicData.access_token, JSON.stringify(body)).subscribe(
                        data => {
                            console.log('response refreshToken', data);
                            this.refreshTokenData = data;
                            this.refreshTokenData = this.refreshTokenData.data;
                            window.localStorage.setItem('token', this.refreshTokenData.access_token);
                            const date = new Date();
                            const milliseconds = date.getTime();
                            // tslint:disable-next-line:no-bitwise
                            const currentTime = milliseconds / 1000 | 0;
                            const expiresOn = currentTime + this.refreshTokenData.expires_in;
                            window.localStorage.setItem('expiresOn', expiresOn);
                            window.localStorage.setItem('refreshToken', this.refreshTokenData.refresh_token);
                            this.token = this.refreshTokenData.access_token;
                            this.refreshToken = this.refreshTokenData.refresh_token;
                            resolve({token: this.token, refresh_token: this.refreshToken});
                        },
                        error => {
                            console.log('error', error);

                            // if refresh token expire, API response
                            // should give specific error so that app knows that the user has to login again.

                            this.onLogout();
                        });
                },
                (error) => {
                    window.localStorage.setItem('loader', 'false');
                    console.log('error', error);
                }
            );

        });

    }

    onLogout() {
        window.localStorage.setItem('loader', 'true');
        setTimeout(() => {
            window.localStorage.setItem('isAuthenticated', 'false');
            window.localStorage.setItem('token', '');
            window.localStorage.setItem('refreshToken', '');
            window.localStorage.setItem('expiresOn', '');
            window.localStorage.setItem('adminProfile', '');

            this.router.navigate(['']);
            window.localStorage.setItem('loader', 'false');
        }, 1000);
    }
}
