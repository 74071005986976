import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../shared.service';
import {DataService} from '../../data.service';
import {RefreshTokenService} from '../../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;
declare var $: any;

@Component({
    selector: 'app-admin-list',
    templateUrl: './admin-list.component.html',
    styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {

    nao = new Nanobar();
    adminsData: any;
    adminId: any;
    adminDeleteData: any;
    tokenData: any;
    token: any;
    clearTimeout: any;
    searchString = '';

    constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('admins');
        this.getAdmins();
    }

    onChangeRouterImage() {
        this.sharedService.sendRouterImageValue('none');
    }

    async getAdmins() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.adminList(this.token, this.searchString).subscribe(
            (data) => {
                console.log('admins data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.adminsData = data;
                if (this.adminsData.is_success === true) {
                    this.adminsData = this.adminsData.data;
                } else {
                    toastr.error(this.adminsData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    openDeleteAdminPopup(id) {
        this.adminId = id;
        $('#adminDeletePopup').modal('show');
    }

    async deleteAdmin() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.deleteAdmin(this.token, this.adminId).subscribe(
            (data) => {
                console.log('admins data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                $('#adminDeletePopup').modal('hide');
                this.adminDeleteData = data;
                if (this.adminDeleteData.is_success === true) {
                    toastr.success(this.adminDeleteData.message);
                    this.getAdmins();
                } else {
                    toastr.error(this.adminDeleteData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    onSearching(ev) {
        const val = ev.target.value;
        console.log('target value', val);
        clearTimeout(this.clearTimeout);
        this.clearTimeout = setTimeout(() => {
            this.searchString = val;
            this.getAdmins();
        }, 750);

    }
}
