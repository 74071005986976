import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../shared.service';
import { debug } from 'util';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    dashboardActive: any;
    userActive: any;
    doubleActive: any;
    adminActive: any;
    spamActive: any;
    profileActive: any;
    url: string;
    routerImage: any;
    adminProfile: any;
    messageActive: any;
    dateNightActive: any;

    constructor(private router: Router, private sharedService: SharedService) {
    }

    ngOnInit() {
        this.routerImage = this.sharedService.getRouterImageValue().subscribe(data => {
            console.log('sub: ', data);
            
            if (data.value === 'none') {
                this.selectionChanged('none');
            } else if (data.value === 'users') {
                this.selectionChanged('users');
            }else if (data.value === 'doubleDate') {
                this.selectionChanged('doubleDate');
            } else if (data.value === 'admins') {
                this.selectionChanged('admins');
            } else if (data.value === 'dashboard') {
                this.selectionChanged('dashboard');
            } else if (data.value === 'spams') {
                this.selectionChanged('spams');
            } else if (data.value === 'gender') {
                this.selectionChanged('gender');
            }else if (data.value === 'datenight') {
                this.selectionChanged('datenight');
            }
            

        });
        
        this.adminProfile = JSON.parse(window.localStorage.getItem('adminProfile'));

        $(document).ready(() => {
            $('body').on('click', '#menu-trigger', function(e) {
                e.preventDefault();

                $('#sidebar').toggleClass('toggled');
                $(this).toggleClass('open');

                if ($(this).hasClass('open')) {
                    $(this).find('i').removeClass('fa-bars').addClass('fa-close');
                } else {
                    $(this).find('i').removeClass('fa-close').addClass('fa-bars');
                }

                $('.sub-menu.toggled').not('.active').each(function() {
                    $(this).removeClass('toggled');
                    $(this).find('ul').hide();
                });

            });
        });

        this.url = window.location.href;
        console.log('url', this.url);
        if (this.url.includes('users')) {
            this.selectionChanged('users');
        } else if (this.url.includes('admins')) {
            this.selectionChanged('admins');
        } else if (this.url.includes('spams')) {
            this.selectionChanged('spams');
            
        } else if (this.url.includes('gender')) {
            this.selectionChanged('gender');
            
        }
        else {
            this.selectionChanged('dashboard');
        }

    }

    menuToggle(id) {
        $('.profile-attributes-menu').css('display', 'none');
        setTimeout(() => {
        // console.log('in toggle chk', id);
        $(id).css('display', 'block');
        }, 500);
      }

      menuToggleSpam(id) {
        $('.spams-menu').css('display', 'none');
        setTimeout(() => {
        // console.log('in toggle chk', id);
        $(id).css('display', 'block');
        }, 500);
      }

    selectionChanged(value) {
        setTimeout(() => {
            
            if (value === 'dashboard') {
                this.dashboardActive = 'assets/speedometer-tool-dark@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
            } else if (value === 'users') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                
                this.userActive = 'assets/user-dark@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
            } else if (value === 'doubleDate') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double-dark.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
            } else if (value === 'admins') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police-dark@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
            } else if (value === 'message') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message-dark.png';
                this.dateNightActive = 'assets/datenight.png';
            } else if (value === 'spams') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(-1@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
                
            } else if (value === 'gender') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon3@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
                
            } else if (value === 'datenight') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
                
            }
            else if (value === 'none') {
                this.dashboardActive = 'assets/speedometer-tool@2x.png';
                this.userActive = 'assets/user@2x.png';
                this.doubleActive = 'assets/double.png';
                this.adminActive = 'assets/police@2x.png';
                this.spamActive = 'assets/mail(1)@2x.png';
                this.profileActive = 'assets/icon1@2x.png';
                this.messageActive = 'assets/message.png';
                this.dateNightActive = 'assets/datenight.png';
            }
        }, 100);
    }
    profileAttrubList() {
         $('#profile-attributes-menu li a').on('click', function(){
        $('li a.current').removeClass('current');
        $(this).addClass('current');
    });

    
    }

    spamList() {
        $('#spams-menu li a').on('click', function(){
       $('li a.current').removeClass('current');
       $(this).addClass('current');
   });

   
   }
   

    onLogout() {
        window.localStorage.setItem('loader', 'true');
        setTimeout(() => {
            window.localStorage.setItem('isAuthenticated', 'false');
            window.localStorage.setItem('token', '');
            window.localStorage.setItem('refreshToken', '');
            window.localStorage.setItem('expiresOn', '');
            window.localStorage.setItem('adminProfile', '');

            this.router.navigate(['']);
            window.localStorage.setItem('loader', 'false');
        }, 1000);
    }

}
