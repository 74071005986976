import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {RefreshTokenService} from '../../refresh-token.service';

declare var toastr: any;
declare var Nanobar: any;
declare var $: any;
//
@Component({
  selector: 'app-spam-list',
  templateUrl: './spam-list.component.html',
  styleUrls: ['./spam-list.component.css']
})
export class SpamListComponent implements OnInit {
  searchString: any;
  nao = new Nanobar();
  spamsData: any;
  token: any;
  tokenData: any;
  userId: any;
  userDeleteData: any;
  clearTimeout: any;

  constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
    this.token = window.localStorage.getItem('token');
  }

  ngOnInit() {
    this.sharedService.sendRouterImageValue('spams');
    this.getSpams();
  }

  async getSpams() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.spamList(this.token, this.searchString).subscribe(
        (data) => {
          console.log('spams data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          this.spamsData = data;
          if (this.spamsData.is_success === true) {
            this.spamsData = this.spamsData.data;
            localStorage.setItem('spams', JSON.stringify(this.spamsData));
          } else {
            toastr.error(this.spamsData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

  openDeleteUserPopup(id) {
    this.userId = id;
    $('#userDeletePopup').modal('show');
  }

  onChangeRouterImage() {
    this.sharedService.sendRouterImageValue('none');
  }

  async deleteUser() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.deleteUser(this.token, this.userId).subscribe(
        (data) => {
          console.log('users data', data);
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          $('#userDeletePopup').modal('hide');
          this.userDeleteData = data;
          if (this.userDeleteData.is_success === true) {
            toastr.success(this.userDeleteData.message);
            this.getSpams();
          } else {
            toastr.error(this.userDeleteData.message);
          }
        },
        (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
        }
    );
  }

  onSearching(ev) {
    const val = ev.target.value;
    console.log('target value', val);
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.searchString = val;
      this.getSpams();
    }, 750);
  }


}
