
//user in delete functions is the current attribute, don't get confused by it..
//name is not change from user to this attribute

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {RefreshTokenService} from '../../refresh-token.service';
declare var Nanobar: any;
declare var toastr: any;
declare var $: any;

@Component({
  selector: 'app-relationship-status',
  templateUrl: './relationship-status.component.html',
  styleUrls: ['./relationship-status.component.css']
})
export class RelationshipStatusComponent implements OnInit {
  nao = new Nanobar();
  usersData: any;
  userId: any;
  configurations: any;
  userDeleteData: any;
  tokenData: any;
  token: any;
  clearTimeout: any;
  searchString = '';
  page = 1;
  offset = 10;
  relationList = [];
  noRelation = false;
  adminAddedRelation: any;
  addConfigData: any;
  varID: any;
  attribute: any = {relationship: ''};
  idToMergeWith = 0;
  idToMerge = 0;
  texToMerge = '';
  mergeAddedRelation: any;
  mergePopData: any;
  configLength: any = 0;
  attributeVal: any;
  constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) { 
    this.token = window.localStorage.getItem('token');
  }

  ngOnInit() {
    this.getConfigurations();
  }

async AddConfig() {
          const body = {
        other_relationship_status: this.attribute.adminAddedRelation
          };
          this.nao.go(30);
          this.tokenData = await this.refreshTokenService.checkRefreshToken();
          this.token = this.tokenData.token;
          this.dataService.addConfigurations(this.token, JSON.stringify(body) ).subscribe(
              (data) => {
                  this.nao.go(100);
                    this.addConfigData = data;
                    $('#addRelation').modal('hide');
              },
                  // this.nao.go(100);
          );
      }

  async getConfigurations() {
    
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.getRelationConfigurations(this.token, this.searchString, this.page, this.offset).subscribe(
        (data) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
            this.configurations = data;
            if (this.configurations.is_success === true) {
              this.configLength = this.configurations.data.length;
                this.configurations = this.configurations.data;
                for (let i = 0; i < this.configurations.length; i++) {
                  this.relationList.push(this.configurations[i]);
              }
              
            }
        },
        (error) => {
          window.localStorage.setItem('loader', 'false');
            console.log('error', error);
        }
    );
}
async mergeConfigs() { 
    
  const body ={
    relationship_id_to_merge: this.idToMerge,
    relationship_id_merged_with: this.idToMergeWith,
    relationship_text_to_merge: this.mergeAddedRelation,
  }
  this.tokenData = await this.refreshTokenService.checkRefreshToken();
  this.token = this.tokenData.token;
  this.dataService.mergeConfigurations(this.token, JSON.stringify(body)).subscribe(
    (data) => {
        this.configurations = data;
        $('#editModal').modal('hide');
        $('#pendingModal').modal('hide');
    },
    (error) => {
      $('#editModal').modal('hide');
      $('#pendingModal').modal('hide');
        console.log('error', error);
    }
);
     
}
openDeleteUserPopup(id) {
  this.userId = id;
  this.varID = this.userId;
  $('#userDeletePopup').modal('show');
}

async deleteUser() {
  this.userId = this.varID;
  const body = {
    delete_relationship_id: this.userId,
  };
  this.nao.go(30);
  window.localStorage.setItem('loader', 'true');
  
  this.tokenData = await this.refreshTokenService.checkRefreshToken();
  this.token = this.tokenData.token;

  this.dataService.deleteConfigurations(this.token, JSON.stringify(body)).subscribe(
      (data) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          $('#userDeletePopup').modal('hide');
          this.userDeleteData = data;
          if (this.userDeleteData.is_success === true) {
              toastr.success(this.userDeleteData.message);
              this.page = 1;
              this.relationList = [];
              this.noRelation = false;
              this.getConfigurations();
          } else {
              toastr.error(this.userDeleteData.message);
          }
          if (this.relationList.length === 0) {
            this.noRelation = true;
        }
      },
      (error) => {
          this.nao.go(100);
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
      }
  );
}
onSearching(ev) {
  const val = ev.target.value;
  clearTimeout(this.clearTimeout);
  this.clearTimeout = setTimeout(() => {
      this.page = 1;
      this.relationList = [];
      this.searchString = val;
      this.getConfigurations();
  }, 750);
}
openMergePopup(text) {
  this.mergePopData = text;
  $('#mergePopup').modal('show');
}
closeMergePopup() {
  $('#mergePopup').modal('hide');
}

//============
onLoadMore() {
  this.page = this.page + 1;
  this.getConfigurations();
}
AttributeIdToMerge(id, val) {
  this.attributeVal = val;
  this.idToMerge = id;
}
AttributeIdToMergeWith(id) {
  this.idToMergeWith = id;
}
}
