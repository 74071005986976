import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'lfs-admin';
  get isAuthenticated(): any {
    return localStorage.getItem('isAuthenticated');
  }
  get isLoader(): any {
    return localStorage.getItem('loader');
  }
}
