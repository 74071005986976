import { Component, OnInit,ElementRef } from '@angular/core';

import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  DataService
} from '../data.service';
import {
  SharedService
} from '../shared.service';
import {
  RefreshTokenService
} from '../refresh-token.service';


declare var Dropzone: any;
declare var $: any;
declare var toastr: any;
declare var Nanobar: any;

@Component({
  selector: 'app-double-user',
  templateUrl: './double-user.component.html',
  styleUrls: ['./double-user.component.css']
})




export class DoubleUserComponent implements OnInit {

  nao = new Nanobar();
    userId: any;
    userData: any;
    userImages1: any;
    userDeleteData1: any;
    userBlockData1: any;
    tokenData: any;
    token: any;
    isActive1: boolean;
    allFiles = [];
    totalFiles = 6;
    videoUrl = '';
    heightInches1: any;
    heightFeet1: any;
    height1: any;


    userImages2: any;
    userDeleteData2: any;
    userBlockData2: any;
    isActive2: boolean;
    

    heightInches2: any;
    heightFeet2: any;
    height2: any;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService, private elRef: ElementRef) {
      this.token = window.localStorage.getItem('token');
  }


  ngOnInit() {
    this.sharedService.sendRouterImageValue('none');
    this.activatedRoute.params.subscribe(params => {
        this.userId = params.id;
        this.getUser();
    });

    this.populateInitialImages();
  }

  async getUser() {
    this.nao.go(30);
    window.localStorage.setItem('loader', 'true');

    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.getUser(this.token, this.userId).subscribe(
        (data) => {
          
            console.log('users data', data);
            this.nao.go(100);
            window.localStorage.setItem('loader', 'false');
            this.userData = data;
            if (this.userData.is_success === true) {
                this.userData = this.userData.data.user;
                this.isActive1 = this.userData.profile1.is_active;
                this.heightFeet1 = Math.floor(this.userData.profile1.profileheight);
                this.heightInches1 = Math.round((this.userData.profile1.profile.height - this.heightFeet1) * 12);
                this.height1 = this.heightFeet1 + "'" + this.heightInches1 + '"';

                this.heightFeet2 = Math.floor(this.userData.profile2.profile.height);
                this.heightInches2 = Math.round((this.userData.profile2.profile.height - this.heightFeet2) * 12);
                this.height1 = this.heightFeet2 + "'" + this.heightInches2 + '"';
                this.populateImages();
            } else {
                toastr.error(this.userData.message);
            }
        },
        (error) => {
            this.nao.go(100);
            window.localStorage.setItem('loader', 'false');
            console.log('error', error);
        }
    );
}

populateInitialImages() {
    for (let i = 0; i < this.totalFiles; i++) {
        this.allFiles.push({
            name: '',
            id: -1,
            isVideo: false,
            url: 'https://d2pk0scdvm6o1i.cloudfront.net/images/detailed/7/D91_Slate_Grey.jpg?t=1511287870',
            videoAvailable: false,
            videoScreenShotUrl: '',
            added: false
        });
    }
}

populateImages() {
    for (let i = 0; i < this.userData.images.length; i++) {
        if (this.userData.images[i].is_video === true) {
            this.allFiles[i].url = this.userData.images[i].original_video_url;
        } else {
            this.allFiles[i].url = this.userData.images[i].object_url;
        }
        this.allFiles[i].name = this.userData.images[i].name;
        this.allFiles[i].id = this.userData.images[i].id;
        this.allFiles[i].isVideo = this.userData.images[i].is_video;
        this.allFiles[i].videoAvailable = this.userData.images[i].video_available;
        this.allFiles[i].videoScreenShotUrl = this.userData.images[i].video_screenshot_url;
        this.allFiles[i].added = true;
    }

}

onPlayVideo(index) {
    this.videoUrl = this.allFiles[index].url;
    const player = this.elRef.nativeElement.querySelector('video');
    player.load();
    $('#showVideo').modal('show');
}


}
