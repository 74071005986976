import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Constants} from './constants';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  publicToken() {
    const headerJson = {'Content-Type': 'application/json'};
    const headers = new HttpHeaders(headerJson);
    const grantType = 'client_credentials';
    const clientId = 'b3j51mtVjYUzO8mkDCw3ArPUHuPwY5qvvO7WH64Q';
    const clientSecret = 'at8Pwpx700qyQB6QXM51T3JuBXGgr9zJfqLPnPE2loeHtaj1t6e1X7qAFBVLKdsxCT2yBAOoSpKukY7M1Pv5OP54o9MpeFdepj0rSrBOCiETFVhq3TAALJYg0UD8pliW';
    const body = JSON.stringify({grant_type: grantType, client_id: clientId, client_secret: clientSecret});
    return this.http.post(Constants.apiAddress + '/auth/token/', body, {headers});
  }

  refreshToken(token, body) {
    console.log('token', token);
    console.log('body', body);
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/api/account/refresh_token/', body, {headers});
  }

  adminLogin(token , email, password) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/account/log_in/', JSON.stringify({email, password}), {headers});
  }

  userList(token, search, days, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/user/?name=' +
        search + '&days=' + days + '&page=' + page + '&offset=' + offset, {headers});
  }

  userListDouble(token, search, days, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/user/double_users_list/?name=' +
        search + '&days=' + days + '&page=' + page + '&offset=' + offset, {headers});
  }

  getScheduledEvents(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/admin/get_event_details/', {headers});
  }


  getUsersList(token,body,page_count ) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/admin/preview_filters_result/?page='+page_count, body, {headers});
  }

  createEvent(token,body ) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/admin/set_event_details/', body, {headers});
  }



  

  getUser(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/user/' + id + '/', {headers});
  }

  getAllConfigurations(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/configurations/', {headers});
  }
  addConfigurations(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/profile/add_new_configurations/', body, {headers});
  }
  mergeConfigurations(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/profile/merge_configuration/', body, {headers});
  }
  getGenderConfigurations(token, search, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/unapproved_gender_configurations/?gender=' +
    search  + '&page=' + page + '&offset=' + offset, {headers});
  }
  getEthnicityConfigurations(token, search, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/unapproved_ethnicity_configurations/?ethnicity=' +
    search  + '&page=' + page + '&offset=' + offset, {headers});
  }
  getMeetAtConfigurations(token, search, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/unapproved_meetat_configurations/?meetat=' +
    search  + '&page=' + page + '&offset=' + offset, {headers});
  }
  getLookingForConfigurations(token, search, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/unapproved_lookingfor_configurations/?lookingfor=' +
    search  + '&page=' + page + '&offset=' + offset, {headers});
  }
  getRelationConfigurations(token, search, page, offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/unapproved_relationshipstatus_configurations/?relationshipstatus=' +
    search  + '&page=' + page + '&offset=' + offset, {headers});
  }

  editUser(token, id, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.put(Constants.apiAddress + '/admin/api/user/' + id + '/', body, {headers});
  }

  deleteUser(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.delete(Constants.apiAddress + '/admin/api/user/' + id + '/', {headers});
  }
  deleteConfigurations(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/profile/delete_configurations/', body, {headers});
  }

  addUser(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/user/', body, {headers});
  }

 

  updateLocation(token, id, body) {
    console.log('body', body);
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/user/' + id + '/location/', body, {headers});
  }

  deleteImage(token, id, imageId) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.delete(Constants.apiAddress + '/admin/api/user/' + id + '/image/' + imageId + '/', {headers});
  }

  addImage(token, id, file, counter) {
    const headerJson = {Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    const formData = new FormData();
    formData.append('data', file);
    formData.append('counter', counter);
    return this.http.post(Constants.apiAddress + '/admin/api/user/' + id + '/image/', formData, {headers});
  }

  addVideo(token, id, file, counter, width, height) {
    console.log('body', file);
    const headerJson = {Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    const formData = new FormData();
    formData.append('data', file);
    formData.append('counter', counter);
    formData.append('width', width);
    formData.append('height', height);
    return this.http.post(Constants.apiAddress + '/admin/api/user/' + id + '/image/upload_video/', formData, {headers});
  }

  adminList(token, search) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/admin/?name=' + search, {headers});
  }

  getAdmin(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/admin/' + id + '/', {headers});
  }

  editAdmin(token, id, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.put(Constants.apiAddress + '/admin/api/admin/' + id + '/', body, {headers});
  }

  deleteAdmin(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.delete(Constants.apiAddress + '/admin/api/admin/' + id + '/', {headers});
  }

  addAdmin(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/admin/', body, {headers});
  }

  changePasswordAdmin(token, id, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/admin/' + id + '/change_password/', body, {headers});
  }

  dashboard(token, days, date1, date2) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    var url = '/admin/api/profile/dashboard/?days=' + days;
    if (date1 && date2)
    {
      url = '/admin/api/profile/dashboard/?start_date=' + date1 + '&end_date=' + date2 //+ '&days=0'
    }
    return this.http.get(Constants.apiAddress + url, {headers});
  }

  dashboardApp(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    var url = '/admin/api/profile/app_stats_locations/';
    
    return this.http.get(Constants.apiAddress + url, {headers});
  }

  appInstallApp(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    var url = '/admin/api/profile/app_install_count/';
    
    return this.http.get(Constants.apiAddress + url, {headers});
  }

  appSessionsApp(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    var url = '/admin/api/profile/app_session_count/';
    
    return this.http.get(Constants.apiAddress + url, {headers});
  }

  //Analytics

  gender_count(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/gender_count/', {headers});
  }

  device_platform(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/device_platform/', {headers});
  }

  age_slabs(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/age_slabs/', {headers});
  }


  dn_status(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/api/v2/profile/date_night/', {headers});
  }
  
  dn_status_update(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/user/date_night/', body, {headers});
  }


  //Match Analytics
  match_stats_gender(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/match_stats_gender/', {headers});
  }

  match_stats_age(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/match_stats_age/', {headers});
  }

  match_stats_matches(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/match_stats_matches/', {headers});
  }

  match_stats_ethnicity(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/match_stats_ethnicity/', {headers});
  }


  match_stats_locations(token) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/match_stats_locations/', {headers});
  }

  


  
  ////////////////////////

  blockUser(token, body) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.post(Constants.apiAddress + '/admin/api/user/block/', body, {headers});
  }

  unblockUser(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.delete(Constants.apiAddress + '/admin/api/user/block/' + id + '/', {headers});
  }

  spamList(token, search) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/spam/', {headers});
  }

  spamListMedia(token,page,offset) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    return this.http.get(Constants.apiAddress + '/admin/api/profile/get_spam_media/?' + 'page=' + page + '&offset=' + offset, {headers});
  }

  deleteMedia(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    const body = JSON.stringify({spam_media_id: id});
    return this.http.post(Constants.apiAddress + '/admin/api/profile/delete_spam_media/',body, {headers});
  }

  deleteEvent(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    const body = JSON.stringify({event_id: id});
    return this.http.post(Constants.apiAddress + '/admin/api/admin/event_cancellation/',body, {headers});
  }

  approveMedia(token, id) {
    const headerJson = {'Content-Type': 'application/json', Authorization: 'Bearer ' + token};
    const headers = new HttpHeaders(headerJson);
    const body = JSON.stringify({spam_media_id: id});
    return this.http.post(Constants.apiAddress + '/admin/api/profile/approve_spam_media/',body, {headers});
  }

}
