import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import {RefreshTokenService} from '../refresh-token.service';

declare var toastr: any;

@Component({
  selector: 'app-date-night',
  templateUrl: './date-night.component.html',
  styleUrls: ['./date-night.component.css']
})
export class DateNightComponent implements OnInit {


  dnEnabled;
  dnTimer;
  dnReps;
  dnErrMsg = "12312323"
  durationEbaled: boolean = false;
  repetitionsEnabled: boolean = false;

  tokenData: any;
  token: any;

  api_data: any;

  constructor(private dataService: DataService, private refreshTokenService: RefreshTokenService) { }



  ngOnInit() {
    this.getDNStatus();
  }

  async getDNStatus() {
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.dn_status(this.token).subscribe(
      (data) => {

          
          window.localStorage.setItem('loader', 'false');
          this.api_data = data;

          this.dnEnabled = this.api_data.data.date_night;
          this.dnTimer = this.api_data.data.time_duration;
          this.dnReps = this.api_data.data.repetitions;
          
      },
      (error) => {
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
      }
  );
}

async postDNData() {
  
  this.tokenData = await this.refreshTokenService.checkRefreshToken();
  this.token = this.tokenData.token;

  const body = {
    date_night : this.dnEnabled,
    time_duration : this.dnTimer,
    repetitions : this.dnReps
  };

  this.dataService.dn_status_update(this.token, JSON.stringify(body)).subscribe(
      (data) => {

        toastr.success('Date Night configurations updated');
          
          
      },
      (error) => {
          
          window.localStorage.setItem('loader', 'false');
          console.log('error', error);
      }
  );
}

  submit(){
    if(this.dnTimer < 30){
      this.durationEbaled = true;
    }
    else{
      this.durationEbaled = false;
    }
    if(this.dnReps < 1){
      this.repetitionsEnabled = true;
    }
    else{
      this.repetitionsEnabled = false;
    }

    if (this.durationEbaled == false && this.repetitionsEnabled == false)
    {
      //Do the shit
      console.log (this.dnEnabled); 

      this.postDNData();

    }
  }

}
