import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../data.service';
import {SharedService} from '../../shared.service';
import {RefreshTokenService} from '../../refresh-token.service';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import { debug } from 'util';

declare var Highcharts: any;
declare var toastr: any;
declare var Nanobar: any;
declare var $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

    nao = new Nanobar();
    usersData: any;
    userId: any;
    userDeleteData: any;
    tokenData: any;
    token: any;
    usersExport = [];
    dashboardData: any;
    dashboardDataAppStats: any;
    appInstallAppStatsVar: any;
    appSessionsAppStatsVar: any;
    userDays = 0;
    clearTimeout: any;
    searchString = '';
    graphDays = 15;
    graphData = [];
    page = 1;
    offset = 25;
    userList = [];
    noUsers = false;
    showCustom = false;
    datepickerModel: Date;
    maxDate: Date;

    matchesTopLocation = '';

    //data = [
        //     {
        //         value : 90,
        //         label : '1',
        //         color : 'rgb(1,0,0,0.9)' 
        //     },
        //     {
        //         value : 100,
        //         label : '2',
        //         color : 'rgb(1,0,0,0.9)'
        //     }
        // ];


        
        options = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                position: 'right',
                display: true,
            },
            tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    
                    var datasetLabel = data.datasets[tooltipItem.datasetIndex].label || 'Other';
                    var label = data.labels[tooltipItem.index];
                    var value = data.datasets[0].data[tooltipItem.index];
                    return label + ': ' + value + '%';
                  }
                }
              }
        };

    //Main
    type_main = 'line';
    data_main = {
        
        labels: [],
        datasets: [
            {
                label: "",
                data: [],
                fill : false,
                borderColor: '', 
                backgroundColor: ''  
            }
        ]
    };
    options_main = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            position: 'top',
            display: true,
        }
    };

    // AGE
    type_age = 'pie';
    data_age = {
        
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    // MATCH AGE
    type_age_match = 'pie';
    data_age_match = {
        
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    //Platform
    type_platform = 'pie';
    data_platform = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    //Gender
    type_gender = 'pie';
    data_gender = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    //Gender
    type_gender_match = 'pie';
    data_gender_match = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    //Matches ETH
    type_eth_match = 'pie';
    data_eth_match = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    //Matches ETH
    type_match = 'pie';
    data_match = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                
            }
        ]
    };

    matches_location = []

    // options_mobile = {
    //     responsive: true,
    //     maintainAspectRatio: true,
    //     legend: {
    //         position: 'bottom',
    //         display: true,
    //     }
    // };
    

    constructor(private router: Router, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('dashboard');
        this.maxDate = new Date();
        this.dashboard('initial');
        this.dashboardAppStats();
        this.appInstallsAppStats();
        this.gender_count();
        this.device_platform();
        this.age_slabs();

    }

    async getUsers() {

        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.userList(this.token, this.searchString, this.userDays, this.page, this.offset).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.usersData = data;
                if (this.usersData.is_success === true) {
                    this.usersExport = [];
                    this.usersData = this.usersData.data;
                    for (let i = 0; i < this.usersData.length; i++) {
                        this.userList.push(this.usersData[i]);
                        this.usersExport.push({
                            Id: this.usersData[i].id,
                            Name: this.usersData[i].first_name,
                            Email: this.usersData[i].email,
                            PhoneNo: this.usersData[i].profile.phone_number,
                            Address: this.usersData[i].location.address
                        });
                    }
                } else {
                    toastr.error(this.usersData.message);
                }

                if (this.userList.length === 0) {
                    this.noUsers = true;
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    //   {{devurl}}admin/api/profile/gender_count
// {{devurl}}admin/api/profile/device_platform
// {{devurl}}admin/api/profile/age_slabs

async age_slabs() {
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.age_slabs(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            if (respData.is_success === true) {
                var keysData = Object.keys(respData.data);
                var dataValues = []
                var totalValue = 0;
                for (let i = 0; i < keysData.length; i++) {
                    dataValues.push(respData.data[keysData[i]])
                    totalValue = totalValue + parseFloat(respData.data[keysData[i]]);
                }
               
                var unknown = Math.fround((100.0 - totalValue));
                unknown = parseFloat(unknown.toFixed(2));
                
                // AGE

                unknown = 0

                var labels = ["16-20", "21-25", "26-30", "31-35", "36-40", "41-45", "46-50","50+"];
                var bgColors = ['rgb(255, 82, 82)','rgb(255, 64, 129)','rgb(224, 64, 251)','rgb(124, 77, 255)','rgb(83, 109, 254)','rgb(255, 195, 0)','rgb(64, 196, 255)','rgb(100, 255, 218)'];

                if (unknown > 1)
                {
                    labels = ["16-20", "21-25", "26-30", "31-35", "36-40", "41-45", "46-50","50+","Not Specified"];
                    bgColors = ['rgb(255, 82, 82)','rgb(255, 64, 129)','rgb(224, 64, 251)','rgb(124, 77, 255)','rgb(83, 109, 254)','rgb(255, 195, 0)','rgb(64, 196, 255)','rgb(100, 255, 218)','rgb(100, 46, 218)'];
                    dataValues.push(unknown)
                }
                

                this.type_age = 'pie';
                this.data_age = {
        
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: bgColors ,
                
                    }
                ]
                };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );


    this.dataService.match_stats_age(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            if (respData.is_success === true) {
                var keysData = Object.keys(respData.data);
                var dataValues = []
                var totalValue = 0;
                for (let i = 0; i < keysData.length; i++) {
                    dataValues.push(respData.data[keysData[i]])
                    totalValue = totalValue + parseFloat(respData.data[keysData[i]]);
                }
               
                var unknown = Math.fround((100.0 - totalValue));
                unknown = parseFloat(unknown.toFixed(2));
                
                // AGE

                var labels = ["16-20", "21-25", "26-30", "31-35", "36-40", "41-45", "46-50","50+"];
                var bgColors = ['rgb(255, 82, 82)','rgb(255, 64, 129)','rgb(224, 64, 251)','rgb(124, 77, 255)','rgb(83, 109, 254)','rgb(255, 195, 0)','rgb(64, 196, 255)','rgb(100, 255, 218)'];

                if (unknown > 1)
                {
                    labels = ["16-20", "21-25", "26-30", "31-35", "36-40", "41-45", "46-50","50+"];
                    bgColors = ['rgb(255, 82, 82)','rgb(255, 64, 129)','rgb(224, 64, 251)','rgb(124, 77, 255)','rgb(83, 109, 254)','rgb(255, 195, 0)','rgb(64, 196, 255)','rgb(100, 255, 218)'];
                    // dataValues.push(unknown)
                }
                

                this.type_age_match = 'pie';
                this.data_age_match = {
        
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: bgColors ,
                
                    }
                ]
                };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );
}

async device_platform() {
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.device_platform(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            var unknown = Math.fround((100.0 - (parseFloat(respData.data.IOS) + parseFloat(respData.data.Android))));
                unknown = parseFloat(unknown.toFixed(2));
            
                var labels = ["iOS", "Android","Unknown"];
                var dataV = [respData.data.IOS, respData.data.Android,unknown];
                var bgColors = ['rgb(0, 121, 107)','rgb(0, 151, 167)','rgb(231, 151, 167)'];

                if (unknown < 1)
                {
                    labels = ["iOS", "Android"];
                    dataV = [respData.data.IOS, respData.data.Android];
                    bgColors = ['rgb(0, 121, 107)','rgb(0, 151, 167)'];
                }


            if (respData.is_success === true) {
                this.type_platform = 'pie';
                this.data_platform = {
                labels: labels,
                datasets: [
                {
                    data: dataV,
                    backgroundColor: bgColors,
                
                }
        ]
        };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );
}

async gender_count() {
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.gender_count(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            
            
            var unknown = Math.fround((100.0 - (parseFloat(respData.data.Male)+ parseFloat(respData.data.Female)+parseFloat(respData.data.Trans_Man)+parseFloat(respData.data.Trans_Woman)+parseFloat(respData.data.other_gender)+parseFloat(respData.data.BiSexual))));
            unknown = parseFloat(unknown.toFixed(2));

            var label = ["Male", "Female", "Trans Man", "Trans Woman", "Bisexual", "Others","Not Specified"]
            var dataV = [respData.data.Male, respData.data.Female,respData.data.Trans_Man,respData.data.Trans_Woman,respData.data.BiSexual,respData.data.other_gender,unknown]
            var bgColor = ['rgb(121, 134, 203)','rgb(240, 98, 146)','rgb(100, 181, 246)','rgb(186, 104, 200)','rgb(156, 90, 220)','rgb(77, 182, 172)','rgb(116, 81, 220)']
        
            if (unknown < 1)
            {
                label = ["Male", "Female", "Trans Man", "Trans Woman","Bisexual", "Others"]
                dataV = [respData.data.Male, respData.data.Female,respData.data.Trans_Man,respData.data.Trans_Woman,respData.data.BiSexual, respData.data.other_gender]
                bgColor = ['rgb(121, 134, 203)','rgb(240, 98, 146)','rgb(100, 181, 246)','rgb(186, 104, 200)','rgb(77, 182, 172)','rgb(57, 111, 165)']
            
            }
            if (respData.is_success === true) {
                this.type_gender = 'pie';
                this.data_gender = {
                labels: label,
                datasets: [
                {
                    data: dataV,
                    backgroundColor: bgColor,
                
                }
        ]
        };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );


    this.dataService.match_stats_gender(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            
            
            var unknown = Math.fround((100.0 - (parseFloat(respData.data.Matches_Male)+ parseFloat(respData.data.Matches_Female)+parseFloat(respData.data.Matches_Trans_Man)+parseFloat(respData.data.Matches_Trans_Woman)+parseFloat(respData.data.Matches_Bisexual))));
            unknown = parseFloat(unknown.toFixed(2));

            var label = ["Male", "Female", "Trans Man", "Trans Woman", "Bisexual", "Others"]
            var dataV = [respData.data.Matches_Male, respData.data.Matches_Female,respData.data.Matches_Trans_Man,respData.data.Matches_Trans_Woman,respData.data.Matches_Bisexual,unknown]
            var bgColor = ['rgb(121, 134, 203)','rgb(240, 98, 146)','rgb(100, 181, 246)','rgb(186, 104, 200)','rgb(156, 90, 220)','rgb(77, 182, 172)']
        
            if (respData.is_success === true) {
                this.type_gender_match = 'pie';
                this.data_gender_match = {
                labels: label,
                datasets: [
                {
                    data: dataV,
                    backgroundColor: bgColor,
                
                }
        ]
        };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );


    this.dataService.match_stats_ethnicity(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            
            
            

            var label = ["White", "Asian", "Black", "Mixed", "Latino", "Middle_Eastern","South_Asian"]
            var dataV = [respData.data.White, respData.data.Asian,respData.data.Black,respData.data.Mixed,respData.data.Latino,respData.data.Middle_Eastern,respData.data.South_Asian]
            var bgColor = ['rgb(121, 134, 203)','rgb(240, 98, 146)','rgb(100, 181, 246)','rgb(186, 104, 200)','rgb(156, 90, 220)','rgb(77, 182, 172)','rgb(177, 82, 120)','rgb(160, 110, 210)']
        
            if (respData.is_success === true) {
                this.type_eth_match = 'pie';
                this.data_eth_match = {
                labels: label,
                datasets: [
                {
                    data: dataV,
                    backgroundColor: bgColor,
                
                }
        ]
        };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );



    
    this.dataService.match_stats_matches(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            
            
            var unknown = Math.fround((100.0 - (parseFloat(respData.data.Male_Male)+ parseFloat(respData.data.Male_Female)+parseFloat(respData.data.Female_Female))));
            unknown = parseFloat(unknown.toFixed(2));

            var label = ["Male-Male", "Male-Female", "Female-Female", "Others"]
            var dataV = [respData.data.Male_Male, respData.data.Male_Female,respData.data.Female_Female, unknown]
            var bgColor = ['rgb(121, 134, 203)','rgb(240, 98, 146)','rgb(100, 181, 246)','rgb(156, 90, 220)']
        
            if (respData.is_success === true) {
                this.type_match = 'pie';
                this.data_match = {
                labels: label,
                datasets: [
                {
                    data: dataV,
                    backgroundColor: bgColor,
                
                }
        ]
        };
            } else {
               
            }
        },
        (error) => {
            
            console.log('error', error);
        }
    );





    this.dataService.match_stats_locations(this.token, ).subscribe(
        (data) => {
            
            
            var respData: any;
            respData = data;

            this.matches_location = respData.data;
            this.matches_location = this.matches_location.slice(0,9);
            

            
        },
        (error) => {
            
            console.log('error', error);
        }
    );






}


async appInstallsAppStats() {
    
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.appInstallApp(this.token).subscribe(
        (data) => {
            
            this.nao.go(100);
            this.appInstallAppStatsVar = data;
            this.appInstallAppStatsVar = this.appInstallAppStatsVar.data;
            
            for (let i=0; i<this.appInstallAppStatsVar.length;i++)
            {
                this.appInstallAppStatsVar[i].version = this.appInstallAppStatsVar[i].version.replace('version','');
            }
            

        },
        (error) => {
            this.nao.go(100);
            console.log('error', error);
        }
    );

    this.dataService.appSessionsApp(this.token).subscribe(
        (data) => {
            
            this.nao.go(100);
            this.appSessionsAppStatsVar = data;
            this.appSessionsAppStatsVar = this.appSessionsAppStatsVar.data;

            for (let i=0; i<this.appSessionsAppStatsVar.length;i++)
            {
                this.appSessionsAppStatsVar[i].version = this.appSessionsAppStatsVar[i].version.replace('version','');
            }
            
        },
        (error) => {
            this.nao.go(100);
            console.log('error', error);
        }
    );
}


async dashboardAppStats() {
    
    this.tokenData = await this.refreshTokenService.checkRefreshToken();
    this.token = this.tokenData.token;

    this.dataService.dashboardApp(this.token).subscribe(
        (data) => {
            console.log('dashboard data', data);
            this.nao.go(100);
            this.dashboardDataAppStats = data;
            // debugger;

            console.log(this.dashboardDataAppStats);
            
        },
        (error) => {
            this.nao.go(100);
            console.log('error', error);
        }
    );
}

    async dashboard(value, date1 = null, date2 = null) {
        if (value === 'initial') {
            window.localStorage.setItem('loader', 'true');
        }

        this.nao.go(30);
        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.dashboard(this.token, this.graphDays,date1,date2).subscribe(
            (data) => {
                console.log('dashboard data', data);
                this.nao.go(100);
                this.dashboardData = data;
                // debugger;

                
                if (this.dashboardData.is_success === true) {
                    this.dashboardData = this.dashboardData.data;
                    this.graphData = [];
                    
                    
                    var dataValue = [];
                    var dataValue1 = [];
                    var dataLabel = [];


                    for (let i = 0; i < this.dashboardData.gragh_data.length; i++) {
                        this.graphData.push(this.dashboardData.gragh_data[i][i]);

                        var keys = Object.keys(this.dashboardData.gragh_data[i]);
                        dataLabel.push(keys[0]);

                        dataValue.push(this.dashboardData.gragh_data[i][keys[0]]);
                    }

                    for (let i = 0; i < this.dashboardData.gragh_data_match_list.length; i++) {
                        
                        var keys = Object.keys(this.dashboardData.gragh_data_match_list[i]);
                        dataValue1.push(this.dashboardData.gragh_data_match_list[i][keys[0]]);
                    }

                    this.data_main = {
        
                        labels: dataLabel,
                        datasets: [
                            {
                                label: "Users",
                                data: dataValue,
                                fill: false,
                                borderColor: 'rgb(48, 63, 159)',
                                backgroundColor: 'rgb(48, 63, 159)',
                            },
                            {
                                label: "Matches",
                                data: dataValue1,
                                fill:false,
                                borderColor: 'rgb(211, 47, 47)',
                                backgroundColor: 'rgb(211, 47, 47)',
                            }
                        ]
                    };

                    if (this.dashboardData.matches_locations.length > 3)
                    {
                        this.dashboardData.matches_locations = this.dashboardData.matches_locations.slice(0, 3)
                    }

                    
                    // var matches_loc = this.dashboardData.matches_locations;
                    // if (matches_loc.length == 0)
                    // {
                    //     this.matchesTopLocation = 'No Matches'
                    // }
                    // else
                    // {
                    //     this.matchesTopLocation = ''
                    //     matches_loc.forEach(element => {
                    //         this.matchesTopLocation = this.matchesTopLocation + element.city + ', ' + element.state
                    //         this.matchesTopLocation = this.matchesTopLocation + '. '
                            
                    //     });
                        
                    // }

                    



                    this.initializeChart();
                } else {
                    toastr.error(this.dashboardData.message);
                }
                if (value === 'initial') {
                    this.getUsers();
                }
            },
            (error) => {
                this.nao.go(100);
                console.log('error', error);
            }
        );
    }

    initializeChart() {
        Highcharts.chart('container', {
            chart: {
                type: 'area',
                height: 305
            },
            title: {
                text: ''
            },
            subtitle: {
                // text: 'Sources: <a href="https://thebulletin.org/2006/july/global-nuclear-stockpiles-1945-2006">' +
                //     'thebulletin.org</a> &amp; <a href="https://www.armscontrol.org/factsheets/Nuclearweaponswhohaswhat">' +
                //     'armscontrol.org</a>'
            },
            xAxis: {
                allowDecimals: false,
                labels: {
                    formatter() {
                        return this.value; // clean, unformatted number for year
                    }
                }
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    formatter() {
                        return this.value / 1000;
                    }
                }
            },
            tooltip: {
                // pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
            },
            plotOptions: {
                area: {
                    pointStart: 1,
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            series: [{
                name: '',
                color: '#c4bebe',
                data: this.graphData
            }]
        });
    }

    onChangeRouterImage() {
        this.sharedService.sendRouterImageValue('none');
    }


    openDeleteUserPopup(id) {
        this.userId = id;
        $('#userDeletePopup').modal('show');
    }

    async deleteUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.deleteUser(this.token, this.userId).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                $('#userDeletePopup').modal('hide');
                this.userDeleteData = data;
                if (this.userDeleteData.is_success === true) {
                    this.userDeleteData = this.userDeleteData.data;
                    this.page = 1;
                    this.userList = [];
                    this.noUsers = false;
                    this.getUsers();
                } else {
                    toastr.error(this.userDeleteData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    exportToCsv() {

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Love at First Swipe Users',
            useBom: false,
            noDownload: false,
            headers: ['Id', 'Name', 'Email', 'Phone Number', 'Address']
        };

        // tslint:disable-next-line:no-unused-expression
        new ngxCsv(this.usersExport, 'lfs Users', options);
    }

    onSearching(ev) {
        const val = ev.target.value;
        console.log('target value', val);
        clearTimeout(this.clearTimeout);
        this.clearTimeout = setTimeout(() => {
            this.page = 1;
            this.userList = [];
            this.noUsers = false;
            this.searchString = val;
            this.getUsers();
        }, 750);

    }

    timeChanged(){
        
        var date1 = this.datepickerModel[0];
        var date2 = this.datepickerModel[1];


        var date1String = date1.getUTCFullYear()+'-'+(date1.getMonth()+1).toString()+'-'+date1.getDate();
        var date2String = date2.getUTCFullYear()+'-'+(date2.getMonth()+1).toString()+'-'+date2.getDate();

        this.data_main = {
            
            labels: [],
            datasets: [
                {
                    label: "",
                    data: [],
                    fill : false,
                    borderColor: '', 
                    backgroundColor: ''  
                }
            ]
        };
        this.type_main = 'line';
        this.dashboard('graph',date1String,date2String);
      }

    onChangingValueGraph(ev) {
        if (ev.target.value == -1)
        {
            this.showCustom = true;
        }
        else
        {
            this.showCustom = false;
            this.graphDays = ev.target.value;
            this.data_main = {
            
                labels: [],
                datasets: [
                    {
                        label: "",
                        data: [],
                        fill : false,
                        borderColor: '', 
                        backgroundColor: ''  
                    }
                ]
            };
            
            if (this.graphDays == 0)
            {
                this.type_main = 'bar';

            }
            else
            {
                this.type_main = 'line';
            }
            this.dashboard('graph');
        }
    }

    onChangingValueUser(ev) {
        this.userDays = ev.target.value;
        this.page = 1;
        this.userList = [];
        this.noUsers = false;
        this.getUsers();
    }

    onLoadMore() {
        this.page = this.page + 1;
        this.getUsers();
    }

}
