import {
    Component,
    ElementRef,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    DataService
} from '../../data.service';
import {
    SharedService
} from '../../shared.service';
import {
    RefreshTokenService
} from '../../refresh-token.service';

declare var Dropzone: any;
declare var $: any;
declare var toastr: any;
declare var Nanobar: any;

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
    nao = new Nanobar();
    userId: any;
    userData: any;
    userImages: any;
    userDeleteData: any;
    userBlockData: any;
    tokenData: any;
    token: any;
    isActive: boolean;
    allFiles = [];
    totalFiles = 6;
    videoUrl = '';
    heightInches: any;
    heightFeet: any;
    height: any;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService, private sharedService: SharedService, private refreshTokenService: RefreshTokenService, private elRef: ElementRef) {
        this.token = window.localStorage.getItem('token');
    }

    ngOnInit() {
        this.sharedService.sendRouterImageValue('none');
        this.activatedRoute.params.subscribe(params => {
            this.userId = params.id;
            this.getUser();
        });

        this.populateInitialImages();
    }

    async getUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.getUser(this.token, this.userId).subscribe(
            (data) => {
                console.log('users data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.userData = data;
                if (this.userData.is_success === true) {
                    this.userData = this.userData.data;
                    this.isActive = this.userData.is_active;
                    this.heightFeet = Math.floor(this.userData.profile.height);
                    this.heightInches = Math.round((this.userData.profile.height - this.heightFeet) * 12);
                    this.height = this.heightFeet + "'" + this.heightInches + '"';
                    this.populateImages();
                } else {
                    toastr.error(this.userData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    populateInitialImages() {
        for (let i = 0; i < this.totalFiles; i++) {
            this.allFiles.push({
                name: '',
                id: -1,
                isVideo: false,
                url: 'https://d2pk0scdvm6o1i.cloudfront.net/images/detailed/7/D91_Slate_Grey.jpg?t=1511287870',
                videoAvailable: false,
                videoScreenShotUrl: '',
                added: false
            });
        }
    }

    populateImages() {
        for (let i = 0; i < this.userData.profile.images.length; i++) {
            if (this.userData.profile.images[i].is_video === true) {
                this.allFiles[i].url = this.userData.profile.images[i].original_video_url;
            } else {
                this.allFiles[i].url = this.userData.profile.images[i].object_url;
            }
            this.allFiles[i].name = this.userData.profile.images[i].name;
            this.allFiles[i].id = this.userData.profile.images[i].id;
            this.allFiles[i].isVideo = this.userData.profile.images[i].is_video;
            this.allFiles[i].videoAvailable = this.userData.profile.images[i].video_available;
            this.allFiles[i].videoScreenShotUrl = this.userData.profile.images[i].video_screenshot_url;
            this.allFiles[i].added = true;
        }
    }

    onPlayVideo(index) {
        this.videoUrl = this.allFiles[index].url;
        const player = this.elRef.nativeElement.querySelector('video');
        player.load();
        $('#showVideo').modal('show');
    }

    openDeleteUserPopup(id) {
        this.userId = id;
        $('#userDeletePopup').modal('show');
    }

    openBlockUserPopup(id) {
        this.userId = id;
        $('#userBlockPopup').modal('show');
    }

    async deleteUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.deleteUser(this.token, this.userId).subscribe(
            (data) => {
                console.log('deleteUser data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                $('#userDeletePopup').modal('hide');
                this.userDeleteData = data;
                if (this.userDeleteData.is_success === true) {
                    toastr.success(this.userDeleteData.message);
                    this.router.navigate(['/users']);
                } else {
                    toastr.error(this.userDeleteData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    async onBlockUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        const body = {
            user_id: this.userId
        };

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.blockUser(this.token, JSON.stringify(body)).subscribe(
            (data) => {
                console.log('blockUser data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                $('#userBlockPopup').modal('hide');
                this.userBlockData = data;
                if (this.userBlockData.is_success === true) {
                    toastr.success('User is blocked successfully');
                    this.isActive = false;
                } else {
                    toastr.error(this.userBlockData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }

    async onUnblockUser() {
        this.nao.go(30);
        window.localStorage.setItem('loader', 'true');

        this.tokenData = await this.refreshTokenService.checkRefreshToken();
        this.token = this.tokenData.token;

        this.dataService.unblockUser(this.token, this.userId).subscribe(
            (data) => {
                console.log('userunBlockData data', data);
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                this.userBlockData = data;
                if (this.userBlockData.is_success === true) {
                    toastr.success('User is unblocked successfully');
                    this.isActive = true;
                } else {
                    toastr.error(this.userBlockData.message);
                }
            },
            (error) => {
                this.nao.go(100);
                window.localStorage.setItem('loader', 'false');
                console.log('error', error);
            }
        );
    }


}