import { Component, OnInit } from '@angular/core';

declare var toastr: any;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email = '';
  emailVerified = false;
  constructor() { }

  ngOnInit() {
  }

  forgotPassword() {
    if (this.email === '') {
      toastr.error('Please enter your email');
      return;
    }
    this.emailVerified = true;
  }

}
